import styled, { css } from 'styled-components';
import { Button } from '@northcoders-dev-team/dorris-design';
export const EditorValuesElement = styled.div`
  display: flex;
  flex-direction: column;

  a {
    width: 100%;
  }
`;

export const ButtonAlign = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
`;

export const CoversationInput = styled.input`
  padding: 0.3em;
  border: none;
  background-color: rgb(0, 0, 0, 0);
  transition: ease-in 0.3s;

  &:hover {
    transition: ease-out 0.3s;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
`;

export const DeleteWrapper = styled.div`
  display: flex;
  align-items: center;
  transition: ease-in 0.3s;

  :hover {
    .deleteBin {
      transition: ease-out 0.3s;
      fill: rgb(250, 80, 80);
    }
  }
`;

export const UpdateError = styled.p`
  color: #f80200;
  text-align: center;
  margin: 0;
  transition: 300ms;
  font-weight: bold;

  ${props =>
    !props.errMsg &&
    css`
      visibility: hidden;
    `};
`;

export const AddParameterButton = styled(Button)`
  min-width: 100px;
  max-width: 140px;
  margin: 0.8em 0 0.8em 0.5em !important;
`;

export const ParameterTypeSelect = styled.select`
  border: none;
  margin: 0;
  background-color: rgb(0, 0, 0, 0);
  border: solid 1px #eeeeee;
  font-size: 1rem;
  font-family: 'Muli';
  color: #7a7a7a;
  margin-top: 0.2em;
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  padding: 2px;
`;

export const ParameterFormContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RequiredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RequiredSwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RequiredOption = styled.span`
  font-size: 0.7rem;
  font-family: 'Muli';
  color: #7a7a7a;
`;

export const ValueSubheader = styled.span`
  font-size: 0.8rem;
  font-family: 'Muli';
  color: #7a7a7a;
  font-weight: bold;
  margin-top: 1rem;
`;

export const ParameterTitleInput = styled.input`
  border: none;
  margin: 0;
  background-color: rgb(0, 0, 0, 0);
  border: solid 1px #eeeeee;
  font-size: 1rem;
  font-family: 'Muli';
  color: #7a7a7a;
  padding: 2px;
`;

export const OpenParameterButton = styled.button`
  margin: 0 0.1em 0 0.1em;
  width: 100%;
  background-color: rgb(0, 0, 0, 0);
  border: none;
  font-size: 1rem;
  font-family: 'Muli';
  color: #7a7a7a;
  padding: 2px;
  text-align: left;
  cursor: pointer;
`;

export const ParameterListItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  vertical-align: center;
  border: solid 1px #eeeeee;
  padding: 6px;
  background-color: #f9fafb;
  cursor: pointer;
  :hover {
    background-color: #ededed;
  }
`;

export const FallbackValueErr = styled.p`
  font-size: 1rem;
  font-family: 'Muli';
  color: #7a7a7a;
  text-align: center;
  width: 80%;
  margin: 10% 10% 0 10%;
`;
