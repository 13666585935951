import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  border: 1px solid #eeeeee;
`;

export const SubmitButton = styled.button`
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

export const Input = styled.input`
  color: #7a7a7a;
  width: 100%;
  font-size: 1rem;
  margin: 0;
  border: solid 0.5px #ddd;
  padding: 0.25rem;
`;
