import React, { useState } from 'react';
import uuidv4 from 'uuid/v4';
import { List } from '@northcoders-dev-team/dorris-design';
import {
  EditorResponsesElement,
  ButtonAlign,
  CoversationInput,
  UpdateError,
  InputWrapper,
  DeleteWrapper,
  UpdateButton,
} from '../elements/EditorResponses';
import ResponseBubble from '../svgs/response';
import Plus from '../svgs/plus';
import Delete from '../svgs/delete';
import AddToList from './AddToList';
import * as api from '../utils/api';
import chartStore from '../stores/chart';
import { useObserver } from 'mobx-react';

function EditorResponses({ editedDisplayName, setEditedDisplayName }) {
  const [errMsg, setErrMsg] = useState(null);

  const handleSubmit = ({ inputValue }, { setValues }) => {
    if (inputValue) {
      chartStore.getSelectedNode().intent.responses.unshift({
        id: uuidv4(),
        body: inputValue,
        edited: true,
        isNew: true,
      });
      setValues({ inputValue: '' });
    }
  };

  const updateIntent = async () => {
    const intentId = chartStore.getSelectedIntentId();
    if (editedDisplayName !== null) {
      if (editedDisplayName === '') {
        return setErrMsg('Conversation title cannot be empty');
      } else if (chartStore.isDisplayNameTaken(editedDisplayName)) {
        return setErrMsg('Conversation title already exists');
      } else {
        setErrMsg(null);
        const newIntent = chartStore
          .getSelectedNode()
          .intent.getApiFormattedIntent();
        await api.patchIntentById(intentId, newIntent);
        chartStore.getSelectedNode().intent.displayName = editedDisplayName;
        setEditedDisplayName(null);
      }
    }

    if (chartStore.getSelectedNode().intent.isResponseEmpty()) {
      return setErrMsg('Responses cannot be blank');
    } else {
      setErrMsg(null);
      const newResponses = chartStore
        .getSelectedNode()
        .intent.getApiFormattedNewResponses();
      const editedResponses = chartStore
        .getSelectedNode()
        .intent.getApiFormattedEditedResponses();
      const newResponsesPromise = newResponses.length
        ? api.postResponses(newResponses, intentId)
        : [];
      const editedResponsesPromise = editedResponses.length
        ? api.patchResponses(editedResponses, intentId)
        : [];
      await Promise.all([newResponsesPromise, editedResponsesPromise]);
      chartStore.getSelectedNode().intent.setResponsesComplete();
    }
  };

  return useObserver(() => (
    <EditorResponsesElement data-test-id="responses">
      <AddToList data-test-id="responseForm" onSubmit={handleSubmit} />
      <List
        items={chartStore
          .getSelectedNode()
          .intent.responses.map((response, index) => ({
            item: (
              <InputWrapper>
                <ResponseBubble
                  size="15px"
                  fill={response.edited ? '#DA6C8C' : '#B0B9CC'}
                />
                <CoversationInput
                  data-test-id="responseInput"
                  value={response.body}
                  onChange={e => {
                    response.body = e.target.value;
                    response.edited = true;
                  }}
                />
                <DeleteWrapper
                  onClick={async () => {
                    await api.deleteResponse(
                      response.id,
                      chartStore.getSelectedIntentId(),
                    );
                    response.removeResponse();
                  }}
                >
                  <Delete
                    size={'15px'}
                    fill={'#DA6C8C'}
                    dataTestId={'deleteResponse'}
                  />
                </DeleteWrapper>
              </InputWrapper>
            ),
          }))}
      />
      <UpdateError data-test-id="updateError" errMsg={errMsg}>
        {errMsg}
      </UpdateError>
      <UpdateButton
        data-test-id="updateIntentButton"
        onClick={updateIntent}
        disabled={
          !chartStore.getSelectedNode().intent.areResponsesEdited() &&
          editedDisplayName === null
        }
        updateDisabled={
          !chartStore.getSelectedNode().intent.areResponsesEdited() &&
          editedDisplayName === null
        }
        bgColor={
          !chartStore.getSelectedNode().intent.areResponsesEdited() &&
          editedDisplayName === null
            ? '#B0B9CC'
            : '#DA6C8C'
        }
      >
        <ButtonAlign>
          <span>Update</span>
          <Plus fill="#ffffff" hoverFill="#ffffff" size={'25px'} />
        </ButtonAlign>
      </UpdateButton>
    </EditorResponsesElement>
  ));
}

export default EditorResponses;
