import React, { useState } from 'react';
import { NavElement, Tab, TabImg, TabText } from '../elements/MenuNav';
import ChatSVG from '../svgs/chat.js';
import CrossSVG from '../svgs/cross.js';
import QuestionMarkSVG from '../svgs/questionMark.js';
import { navigate } from '@reach/router';

function MenuNav() {
  const [active, setActive] = useState('Dialogue');
  const tabs = ['Dialogue', 'Values', 'Fallback', 'Help'];

  return (
    <NavElement>
      {tabs.map(tab => {
        const size = '15px';
        const colour = active === tab ? '#35A23A' : '#B0B9CC';
        return (
          <Tab
            key={tab}
            onClick={() => {
              navigate(`/${tab.toLowerCase()}`);
              setActive(tab);
            }}
          >
            <TabImg active={active === tab}>
              {tab === 'Dialogue' ? (
                <ChatSVG size={size} fill={colour} />
              ) : tab === 'Fallback' ? (
                <CrossSVG size={size} fill={colour} />
              ) : (
                <QuestionMarkSVG size={size} fill={colour} />
              )}
            </TabImg>
            <TabText id={`tabText${tab}`} active={active === tab}>
              {tab}
            </TabText>
          </Tab>
        );
      })}
    </NavElement>
  );
}

export default MenuNav;
