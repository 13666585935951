import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import {
  ModalSection,
  ModalTitle,
  ModalSubheader,
  ParameterTypeContainer,
  ParameterTypeSelect,
  RequiredContainer,
  RequiredSwitchContainer,
  RequiredOption,
  UpdateError,
  UpdateParameterButton,
  ButtonAlign,
  ParameterOptionsContainer,
  InputWrapper,
  PromptInput,
  DeleteWrapper,
  CenterStyle,
  PromptsNotRequiredMsg,
} from '../elements/EditorValueModal';
import AddToList from './AddToList';
import Switch from '@material-ui/core/Switch';
import Plus from '../svgs/plus';
import Basket from '../svgs/basket';
import Delete from '../svgs/delete';
import chartStore from '../stores/chart';
import entityTypesStore from '../stores/entityTypes';
import * as api from '../utils/api';
import { List } from '@northcoders-dev-team/dorris-design';
import { useObserver } from 'mobx-react';

const EditorValueModal = ({ modalValueIndex, setModalValueIndex }) => {
  const [errMsg, setErrMsg] = useState(null);
  const [valueTitle, setValueTitle] = useState(
    chartStore.getSelectedNode().intent.parameters[modalValueIndex].displayName,
  );
  const [valueType, setValueType] = useState(
    chartStore.getSelectedNode().intent.parameters[modalValueIndex]
      .entityTypeDisplayName,
  );
  const [valueRequired, setValueRequired] = useState(
    chartStore.getSelectedNode().intent.parameters[modalValueIndex].mandatory,
  );
  const [valuePrompts, setValuePrompts] = useState([
    ...chartStore.getSelectedNode().intent.parameters[modalValueIndex].prompts,
  ]);
  const [isEdited, setIsEdited] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUpdate = async () => {
    if (valueTitle === '') {
      return setErrMsg('Value title cannot be empty');
    } else if (!/^[a-z0-9_-]+$/i.test(valueTitle)) {
      return setErrMsg('Value title must consist of letters,numbers,-,_');
    } else {
      setErrMsg(null);
      let selectedParameter = chartStore.getSelectedNode().intent.parameters[
        modalValueIndex
      ];

      selectedParameter.displayName = valueTitle;
      selectedParameter.value = `$${valueTitle}`;
      selectedParameter.entityTypeDisplayName = valueType;
      selectedParameter.mandatory = valueRequired;
      selectedParameter.prompts = valuePrompts;

      const newIntent = chartStore
        .getSelectedNode()
        .intent.getApiFormattedIntent();

      setIsSubmitting(true);
      await api
        .patchIntentById(chartStore.getSelectedIntentId(), newIntent)
        .catch(err => setErrMsg(err.response.data.msg))
        .finally(() => setIsSubmitting(false));

      setIsEdited(false);
    }
  };

  const handleAddParameter = ({ inputValue }, { setValues }) => {
    if (inputValue) {
      setIsEdited(true);
      const newValuePrompts = [...valuePrompts];
      newValuePrompts.unshift(inputValue);
      setValuePrompts(newValuePrompts);
      setValues({ inputValue: '' });
    }
  };

  const formatPromptsList = (prompt, promptIndex) => ({
    item: (
      <InputWrapper>
        <PromptInput
          data-test-id="promptInput"
          value={prompt}
          onChange={e => {
            setIsEdited(true);
            const { value } = e.target;
            setValuePrompts(prompts =>
              prompts.map((p, i) => {
                if (i === promptIndex) {
                  return value;
                }
                return p;
              }),
            );
          }}
        />
        <DeleteWrapper
          onClick={() => {
            let newPrompts = [...valuePrompts];
            setIsEdited(true);
            newPrompts.splice(promptIndex, 1);
            setValuePrompts(newPrompts);
          }}
        >
          <Delete size={'15px'} fill={'#DA6C8C'} dataTestId={'deletePrompt'} />
        </DeleteWrapper>
      </InputWrapper>
    ),
  });

  return useObserver(() => (
    <Modal
      open={modalValueIndex !== null}
      onClose={() => {
        if (!isSubmitting) {
          setModalValueIndex(null);
        }
      }}
      data-test-id="promptsModal"
    >
      <CenterStyle>
        <ModalSection data-test-id="modalSection">
          <Basket size="15px" fill="#DA6C8C" />
          <ModalTitle
            data-test-id="valueTitle"
            onChange={e => {
              setIsEdited(true);
              setValueTitle(e.target.value);
            }}
            value={valueTitle}
          />
          <ParameterOptionsContainer>
            <ParameterTypeContainer>
              <ModalSubheader>Value Type</ModalSubheader>
              <ParameterTypeSelect
                data-test-id="valueType"
                defaultValue={valueType}
                onChange={e => {
                  setIsEdited(true);
                  setValueType(e.target.value);
                }}
              >
                {entityTypesStore.entityTypes.map(entityType => {
                  return (
                    <option
                      key={entityType.displayName}
                      value={entityType.displayName}
                    >
                      {entityType.displayName}
                    </option>
                  );
                })}
                {entityTypesStore.gcpEntityTypes.map(entityType => {
                  return (
                    <option key={entityType} value={entityType}>
                      {entityType}
                    </option>
                  );
                })}
              </ParameterTypeSelect>
            </ParameterTypeContainer>
            <RequiredContainer>
              <ModalSubheader>Required</ModalSubheader>
              <RequiredSwitchContainer>
                <RequiredOption>No</RequiredOption>
                <Switch
                  data-test-id="valueSwitch"
                  checked={valueRequired}
                  onChange={e => {
                    setIsEdited(true);
                    setValueRequired(e.target.checked);
                  }}
                />
                <RequiredOption>Yes</RequiredOption>
              </RequiredSwitchContainer>
            </RequiredContainer>
          </ParameterOptionsContainer>
          {valueRequired ? (
            <>
              <AddToList
                data-test-id="promptFormInput"
                onSubmit={handleAddParameter}
                placeholder="Enter a new value question prompt..."
              ></AddToList>
              <List
                items={valuePrompts.map(formatPromptsList)}
                data-test-id="promptList"
              />
            </>
          ) : (
            <PromptsNotRequiredMsg>
              If the value is required then the bot will prompt the user for the
              value, if not then the user can progress without supplying the
              information
            </PromptsNotRequiredMsg>
          )}
          <CenterStyle>
            <UpdateError data-test-id="updateError" errMsg={errMsg}>
              {errMsg}
            </UpdateError>
            <UpdateParameterButton
              data-test-id="updateIntentButton"
              onClick={handleUpdate}
              disabled={!isEdited}
              updateDisabled={!isEdited}
              bgColor={!isEdited ? '#B0B9CC' : '#DA6C8C'}
            >
              <ButtonAlign>
                <span>Update</span>
                <Plus fill="#ffffff" hoverFill="#ffffff" size={'25px'} />
              </ButtonAlign>
            </UpdateParameterButton>
          </CenterStyle>
        </ModalSection>
      </CenterStyle>
    </Modal>
  ));
};

export default EditorValueModal;
