import uuid from 'uuid/v4';

export const formatIntentNodes = intents => {
  const type = 'conversationPoint';

  return intents.reduce((nodes, intent) => {
    nodes[intent.name] = {
      id: intent.name,
      type,
      intent,
      position: {
        id: intent.position_id,
        x: intent.x,
        y: intent.y,
      },
      ports: {
        portInput: {
          id: 'portInput',
          type: 'input',
          properties: {
            type: `${type}-input`,
          },
        },
        portOutput: {
          id: 'portOutput',
          type: 'output',
          properties: {
            type: `${type}-output`,
          },
        },
      },
    };

    return nodes;
  }, {});
};

export const addMetrics = (nodes, metrics) => {
  const newNodes = { ...nodes };
  metrics.forEach(metric => {
    if (newNodes[metric.intent_name]) {
      newNodes[metric.intent_name] = {
        ...newNodes[metric.intent_name],
        metrics: {
          sentiment_average: (10 * metric.sentiment_average).toFixed(1),
          dropoff_rate: (100 * metric.dropoff_rate).toFixed(1) + '%',
        },
      };
    }
  });
  return newNodes;
};

export const createNode = ({ id, values, type, position }) => {
  return {
    ...values,
    id,
    type,
    position,
    ports: {
      portInput: {
        id: 'portInput',
        type: 'input',
        properties: {
          type: `${type}-input`,
        },
      },
      portOutput: {
        id: 'portOutput',
        type: 'output',
        properties: {
          type: `${type}-output`,
        },
      },
    },
  };
};

export const createLinks = intents => {
  const intentNamesRef = intents.reduce((refObj, intent) => {
    if (intent.outputContexts.length) {
      intent.outputContexts.forEach(context => {
        refObj[context.name] = refObj[context.name]
          ? [...refObj[context.name], intent.name]
          : [intent.name];
      });
    }
    return refObj;
  }, {});

  return intents.reduce((acc, intent) => {
    if (intent.inputContextNames.length) {
      intent.inputContextNames.forEach(inputContext => {
        if (intentNamesRef[inputContext]) {
          intentNamesRef[inputContext].forEach(context => {
            const linkId = uuid();
            acc[linkId] = {
              id: linkId,
              from: {
                nodeId: context,
                portId: 'portOutput',
              },
              to: {
                nodeId: intent.name,
                portId: 'portInput',
              },
            };
          });
        }
      });
    }
    return acc;
  }, {});
};
