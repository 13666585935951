/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import EditorTrainingPhrases from './EditorTrainingPhrases';
import EditorResponses from './EditorResponses';
import EditorValues from './EditorValues';
import {
  EditorElement,
  PersonaSelector,
  ConversationTitle,
  MainViewSwitch,
  SaveButton,
  ResetButton,
  AddPointButton,
} from '../elements/Editor';
import {
  formatIntentNodes,
  createLinks,
  addMetrics,
} from '../utils/flowDiagram';
import PersonaSwitch from '../components/PersonaSwitch';
import Tabs, { Tab } from '../components/Tabs';
import * as api from '../utils/api';
import chartStore from '../stores/chart';
import { useObserver } from 'mobx-react';

function Editor({
  slideRightPanel,
  setSlideRightPanel,
  mainView,
  setMainView,
  addFieldValues,
  editFieldValue,
  updateCurrentFieldValues,
  deleteFieldValue,
  nodes,
  updateIntentValue,
  addNewNode,
  createDisplayNameArray,
  updateIntent,
}) {
  const [editedDisplayName, setEditedDisplayName] = useState(null);
  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    const resizeView = e => {
      if (dragging) {
        let columnWidth = window.innerWidth - e.clientX;
        if (columnWidth > 2 * (window.innerWidth / 3)) {
          columnWidth = 2 * (window.innerWidth / 3);
        }
        setSlideRightPanel(columnWidth + 'px');
      }
    };

    const handleMouseUp = () => {
      setDragging(false);
    };

    window.addEventListener('mousemove', resizeView);
    window.addEventListener('mouseup', handleMouseUp);
    return () => {
      window.removeEventListener('mousemove', resizeView);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [dragging, setSlideRightPanel]);

  const recordTime = () => {
    setDragging(true);
  };

  const updatePositions = async nodes => {
    const newPositions = Object.values(nodes).reduce((acc, node) => {
      const { id, x, y } = node.position;
      if (id) acc.push({ id, x, y });

      return acc;
    }, []);
    const positions = await api.patchPositions(newPositions);
    return positions;
  };

  const newIntents = async () => {
    const [intents, analytics] = await Promise.all([
      api.getIntents(),
      api.getAnalytics(),
    ]);

    const nodes = formatIntentNodes(intents);
    const nodesWithMetrics = addMetrics(nodes, analytics);
    const links = createLinks(intents);

    chartStore.updateNodes(nodesWithMetrics);
    chartStore.links = links;
  };

  return useObserver(() =>
    chartStore.getSelectedNode() ? (
      <>
        <MainViewSwitch
          id="mainViewSwitch"
          slideRightPanel={slideRightPanel}
          mainView={mainView}
          onMouseDown={recordTime}
        >
          ⭤
        </MainViewSwitch>
        <AddPointButton
          onClick={addNewNode}
          slideRightPanel={slideRightPanel}
          data-test-id="add-point-button"
        >
          +
        </AddPointButton>
        <SaveButton
          onClick={() => {
            updatePositions(nodes);
          }}
          slideRightPanel={slideRightPanel}
        >
          Save Layout
        </SaveButton>
        <ResetButton
          onClick={async () => {
            let resetChoice = confirm(
              'Are you sure you want to reset this bot?',
            );
            if (resetChoice === true) {
              await api.resetBot();
              newIntents();
              return true;
            } else {
              return false;
            }
          }}
          slideRightPanel={slideRightPanel}
          data-test-id="save-layout-button"
        >
          Reset Bot
        </ResetButton>
        <EditorElement mainView={mainView} data-test-id="editor">
          -
          <PersonaSelector>
            <PersonaSwitch />
          </PersonaSelector>
          <ConversationTitle
            placeholder={'Conversation title...'}
            data-test-id="conversation-title"
            value={
              editedDisplayName === null
                ? chartStore.getSelectedNode().intent.displayName
                : editedDisplayName
            }
            onChange={e => setEditedDisplayName(e.target.value)}
          />
          <Tabs>
            <Tab title="Training">
              <EditorTrainingPhrases
                updateIntent={updateIntent}
                selectedChartNodeIntent={chartStore.getSelectedNode().intent}
                trainingPhrases={
                  chartStore.getSelectedNode().intent.trainingPhrases
                }
                intentName={chartStore.getSelectedNode().intent.name}
                editedDisplayName={editedDisplayName}
                setEditedDisplayName={setEditedDisplayName}
                addFieldValues={addFieldValues}
                editFieldValue={editFieldValue}
                updateCurrentFieldValues={updateCurrentFieldValues}
                deleteFieldValue={deleteFieldValue}
                updateIntentValue={updateIntentValue}
                createDisplayNameArray={createDisplayNameArray}
              />
            </Tab>
            <Tab title="Values">
              <EditorValues />
            </Tab>
            <Tab title="Responses">
              <EditorResponses
                editedDisplayName={editedDisplayName}
                setEditedDisplayName={setEditedDisplayName}
              />
            </Tab>
          </Tabs>
        </EditorElement>
      </>
    ) : (
      <>
        <MainViewSwitch
          id="mainViewSwitch"
          slideRightPanel={slideRightPanel}
          mainView={mainView}
          onMouseDown={recordTime}
        >
          ⭤
        </MainViewSwitch>
        <AddPointButton
          onClick={addNewNode}
          slideRightPanel={slideRightPanel}
          data-test-id="add-point-button"
        >
          +
        </AddPointButton>
        <SaveButton
          onClick={() => {
            updatePositions(nodes);
          }}
          slideRightPanel={slideRightPanel}
          data-test-id="save-layout-button"
        >
          Save Layout
        </SaveButton>
        <ResetButton
          onClick={() => {
            let resetChoice = confirm(
              'Are you sure you want to reset this bot?',
            );
            if (resetChoice === true) {
              api.resetBot();
              return true;
            } else {
              return false;
            }
          }}
          slideRightPanel={slideRightPanel}
          data-test-id="save-layout-button"
        >
          Reset Bot
        </ResetButton>
        <EditorElement mainView={mainView}>
          <p></p>
        </EditorElement>
      </>
    ),
  );
}

export default Editor;
