import React, { useState, useEffect } from 'react';
import { List } from '@northcoders-dev-team/dorris-design';
import {
  EditorTrainingPhrasesElement,
  ButtonAlign,
  ConversationInput,
  InputWrapper,
  DeleteWrapper,
  UpdateButton,
  UpdateError,
} from '../elements/EditorTrainingPhrases';
import TrainingPhraseBubble from '../svgs/response';
import Plus from '../svgs/plus';
import Delete from '../svgs/delete';
import AddToList from './AddToList';
import * as api from '../utils/api';
import { useObserver } from 'mobx-react';
import chartStore from '../stores/chart';
import training from '../stores/training';

function EditorTrainingPhrases({
  intentName,
  editFieldValue,
  editedDisplayName,
  setEditedDisplayName,
  createDisplayNameArray,
  selectedChartNodeIntent,
  updateIntent,
}) {
  const [newTrainingPhrases, setNewTrainingPhrases] = useState([]);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [errMsg, setErrMsg] = useState(null);

  useEffect(() => {
    setNewTrainingPhrases([]);
  }, [intentName]);

  const handleDeleteNewTrainingPhrase = trainingPhrase_id => {
    setNewTrainingPhrases(
      newTrainingPhrases.filter(
        (trainingPhrase, index) => index !== trainingPhrase_id,
      ),
    );
  };

  const handleDeleteTrainingPhrase = async trainingPhrase_index => {
    const intentId = intentName.match(/\/intents\/(.*)/)[1];
    const trainingPhrases = chartStore.getSelectedNode().toJSON().intent
      .trainingPhrases;
    const filteredTrainingPhrases = trainingPhrases
      .filter((trainingPhrase, index) => index !== trainingPhrase_index)
      .map(trainingPhrase => {
        return trainingPhrase.map(part => ({ text: part.text }));
      });
    await api.patchTrainingPhrases(filteredTrainingPhrases, intentId);

    chartStore
      .getSelectedNode()
      .intent.trainingPhrases.splice(trainingPhrase_index, 1);
  };

  const trainingPhraseListItems = newTrainingPhrases.map(
    (trainingPhrase, trainingPhraseIndex) => {
      const textNewValue = trainingPhrase.reduce((accum, current) => {
        return accum + current.text;
      }, '');
      return {
        item: (
          <InputWrapper>
            <TrainingPhraseBubble size="15px" fill="#DA6C8C" />
            <ConversationInput
              data-test-id="trainingPhraseInput"
              value={textNewValue}
              onChange={e => {
                const eventValue = e.target.value;
                setNewTrainingPhrases(currentTrainingPhrases =>
                  currentTrainingPhrases.map(
                    (currentTrainingPhrase, currentTrainingPhraseIndex) => {
                      if (trainingPhraseIndex === currentTrainingPhraseIndex) {
                        return [{ text: eventValue }];
                      } else {
                        return currentTrainingPhrase;
                      }
                    },
                  ),
                );
              }}
            />
            <DeleteWrapper
              onClick={() => {
                handleDeleteNewTrainingPhrase(trainingPhraseIndex);
              }}
            >
              <Delete
                size={'15px'}
                fill={'#DA6C8C'}
                dataTestId={'deleteTrainingPhrase'}
              />
            </DeleteWrapper>
          </InputWrapper>
        ),
      };
    },
  );

  const formatListItems = (trainingPhrase, index) => {
    let [{ edited }] = trainingPhrase;
    const textValue = trainingPhrase.reduce((accum, current) => {
      return accum + current.text;
    }, '');
    return {
      item: (
        <InputWrapper>
          <TrainingPhraseBubble
            size="15px"
            fill={edited ? '#DA6C8C' : '#B0B9CC'}
          />
          <ConversationInput
            data-test-id="trainingPhraseInput"
            value={textValue}
            onChange={e => {
              setHasUpdated(true);
              editFieldValue(
                intentName,
                index,
                e.target.value,
                'trainingPhrases',
              );
            }}
          />
          <DeleteWrapper
            onClick={() => {
              handleDeleteTrainingPhrase(index);
            }}
          >
            <Delete
              size={'15px'}
              fill={'#DA6C8C'}
              dataTestId={'deleteTrainingPhrase'}
            />
          </DeleteWrapper>
        </InputWrapper>
      ),
    };
  };

  const handleSubmit = ({ inputValue }, { setValues }) => {
    if (inputValue) {
      setNewTrainingPhrases(currPhrases => [
        [{ text: inputValue }],
        ...currPhrases,
      ]);
      setValues({ inputValue: '' });
    }
  };

  const updateTrainingPhrases = async () => {
    const intentId = intentName.match(/\/intents\/(.*)/)[1];
    let {
      displayName: display_name,
      trainingPhrases: training_phrases,
      inputContextNames: input_context_names,
      responses,
      position_id,
      x,
      y,
      ...restOfIntent
    } = selectedChartNodeIntent;
    const newDisplayName = editedDisplayName !== null;

    if (newDisplayName) {
      if (editedDisplayName === '') {
        return setErrMsg('Conversation title is empty');
      } else if (chartStore.isDisplayNameTaken(editedDisplayName)) {
        return setErrMsg('Conversation title already exists');
      } else {
        display_name = editedDisplayName;
      }
    }

    if (newTrainingPhrases.length > 0) {
      training_phrases = [...newTrainingPhrases].concat(training_phrases);
      setNewTrainingPhrases([]);
      training.startTimeout();
    }

    if (hasUpdated) {
      if (
        training_phrases.some(trainingPhrase => trainingPhrase[0].text === '')
      ) {
        return setErrMsg('Training phrases cannot be blank');
      }
      training_phrases = training_phrases.map(trainingPhrase => {
        if (trainingPhrase[0].edited)
          return trainingPhrase.map(({ edited, ...part }) => part);

        return trainingPhrase;
      });
      setHasUpdated(false);
    }
    const updatedIntent = await api.patchIntentById(intentId, {
      ...restOfIntent,
      display_name,
      training_phrases,
      input_context_names,
    });
    updateIntent(intentName, {
      ...updatedIntent,
      trainingPhrases: training_phrases,
      responses,
      position_id,
      x,
      y,
    });
    setNewTrainingPhrases([]);
    setErrMsg(null);
    setEditedDisplayName(null);
  };

  const updateDisabled =
    newTrainingPhrases.length === 0 &&
    !hasUpdated &&
    editedDisplayName === null;

  return useObserver(() => (
    <EditorTrainingPhrasesElement data-test-id="trainingPhrases">
      <AddToList
        data-test-id="trainingPhraseForm"
        onSubmit={handleSubmit}
        placeholder="Enter a new Training Phrase..."
      />
      <List
        items={[
          ...trainingPhraseListItems,
          ...chartStore
            .getSelectedNode()
            .toJSON()
            .intent.trainingPhrases.map(formatListItems),
        ]}
      />
      <UpdateError data-test-id="updateError" errMsg={errMsg}>
        {errMsg}
      </UpdateError>
      <UpdateButton
        data-test-id="updateIntentButton"
        onClick={updateTrainingPhrases}
        disabled={updateDisabled}
        updateDisabled={updateDisabled}
        bgColor={updateDisabled ? '#B0B9CC' : '#DA6C8C'}
      >
        <ButtonAlign data-test-id="updateTrainingPhrases">
          <span>Update</span>
          <Plus fill="#ffffff" hoverFill="#ffffff" size={'25px'} />
        </ButtonAlign>
      </UpdateButton>
    </EditorTrainingPhrasesElement>
  ));
}

export default EditorTrainingPhrases;
