import React from "react";

import Switch from "../components/Switch";

function PersonaSwitch(prop) {
  return (
    <>
      <div>Formal</div>
      <Switch />
      <div>Informal</div>
    </>
  );
}

export default PersonaSwitch;
