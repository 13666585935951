import styled from 'styled-components';

export const ValuesContainer = styled.div`
  width: 100%;
  display: grid;
  grid-area: dashboard;
  grid-template-columns: auto ${props => props.slideRightPanel};
  grid-template-rows: 60vh 40vh;
  grid-template-areas:
    'primary secondary'
    'primary preview';
`;

export const ValuesSection = styled.section`
  grid-area: primary;
  background: white;
  padding: 4rem;
  overflow-y: scroll;
`;

export const ValuesHeading = styled.h1`
  font-size: 2rem;
  margin: 0;
  color: #b0b9cc;
`;

export const ValuesSubHeading = styled.p`
  font-size: 1rem;
  color: #b0b9cc;
`;

export const ValuesForm = styled.div`
  display: flex;
  justify-content: center;
  width: 400px;
`;

export const EditorPlaceholder = styled.div`
  box-shadow: -5px 2px 5px rgba(198, 198, 198, 0.25);
  background: white;
  z-index: 10;
`;
