import Chart from '../models/Chart';
import { unprotect } from 'mobx-state-tree';

const chart = Chart.create({
  offset: { x: 0, y: 0 },
  nodes: {},
  links: {},
  selected: {},
  hovered: { type: null, id: null },
});

// selected: type: null, id: null

unprotect(chart);

export default chart;
