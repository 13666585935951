import React from 'react';

export default ({ fill = '#C3C6CD', size = '25px' }) => {
  return (
    // <svg width={size} height={size} viewBox="0 0 535.5 535.5">
    <svg viewBox="0 0 433.102 433.102" width={size} height={size}>
      <g>
        <g>
          <g>
            <path
              d="M429.902,56.782c0-1.024-1.024-2.048-1.536-2.048c-1.024,0-2.048,0-2.56,0.512l-46.08,45.056L332.11,52.686l46.08-46.08    c1.024-1.024,1.024-2.56,0-3.584c-0.512-0.512-0.512-0.512-1.024-0.512c-34.816-7.68-70.656,2.56-96.768,26.624    c-24.576,24.576-18.432,53.248-14.336,72.192c0.512,2.56,1.024,5.632,1.536,7.68c-16.384,17.408-140.288,141.824-158.72,159.232    c-2.048-0.512-4.096-1.024-6.656-1.536c-18.944-4.608-46.592-11.264-72.192,13.824c-24.064,25.6-34.304,61.44-26.624,95.744    c0.512,1.536,1.536,2.048,3.072,2.048c0.512,0,1.024-0.512,1.024-0.512l46.08-45.056l47.616,47.616l-46.08,46.08    c-1.024,1.024-1.024,2.56,0,3.584c0.512,0.512,0.512,0.512,1.024,0.512c7.168,1.536,14.848,2.56,22.528,2.56    c27.648,0,54.272-10.24,74.24-29.696c24.576-24.576,18.432-53.248,14.336-72.192c-0.512-2.56-1.024-5.632-1.536-7.68    c16.384-17.408,140.288-141.824,158.72-159.232l6.656,1.536c18.432,4.608,47.104,11.776,72.192-13.312    C427.342,126.926,437.07,91.086,429.902,56.782z"
              data-original="#000000"
              className="active-path"
              data-old_color="#000000"
              fill="#B1B9CA"
            />
          </g>
        </g>
      </g>{' '}
    </svg>
  );
};
