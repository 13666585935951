import React, { useEffect } from 'react';
import FlowDiagram from './FlowDiagram';
import Editor from './Editor';
import MessagePreview from './MessagePreview';
import { DialogueElement } from '../elements/DialogueElement';
import * as api from '../utils/api';
import * as actions from '../utils/actions';
import {
  convertFromVwToPx,
  listDisplayNames,
  newCPName,
} from '../utils/dialogue';
import chartStore from '../stores/chart';
import { useObserver } from 'mobx-react';

function Dialogue({
  mainView,
  setMainView,
  slideMenu,
  slideRightPanel,
  setSlideRightPanel,
}) {
  const createDisplayNameArray = () => {
    return listDisplayNames(chartStore.nodes);
  };

  const addNewNode = async () => {
    const newIntents = [
      {
        display_name: newCPName(chartStore.nodes),
        training_phrases: [],
      },
    ];
    const [newIntent] = await api.postIntents(newIntents);
    const winW = window.innerWidth; // px
    const winH = window.innerHeight; // px

    const slideRightPanelPx = convertFromVwToPx(slideRightPanel, winW);
    const slideLeftMenuPx = convertFromVwToPx(slideMenu, winW);

    const newNodeX =
      winW - slideLeftMenuPx - slideRightPanelPx - chartStore.offset.x - 285; // px
    const newNodeY = winH - chartStore.offset.y - 270; // px

    newIntent.x = newNodeX;
    newIntent.y = newNodeY;

    const newNode = {
      id: newIntent.name,
      type: 'conversationPoint',
      intent: { ...newIntent, responses: [] },
      position: {
        id: newIntent.position_id,
        x: newNodeX,
        y: newNodeY,
      },
      ports: {
        portInput: {
          id: 'portInput',
          type: 'input',
          properties: {
            type: 'conversationPoint-input',
          },
          position: {
            x: 138,
            y: 0,
          },
        },
        portOutput: {
          id: 'portOutput',
          type: 'output',
          properties: {
            type: 'conversationPoint-output',
          },
          position: {
            x: 137.5,
            y: 219.5,
          },
        },
      },
      size: {
        width: 275,
        height: 212,
      },
    };

    chartStore.nodes.set(newIntent.name, newNode);
  };

  const updateIntent = (nodeId, newIntent) => {
    chartStore.nodes.get(nodeId).intent = newIntent;
  };

  const addFieldValues = (fieldValues, nodeId, field) => {
    chartStore.nodes.get(nodeId).intent[field] = fieldValues.concat(
      chartStore.nodes.get(nodeId).intent[field],
    );
  };

  const swapOutOldFieldValues = (oldFieldValues, field) => {
    const newFieldValues = oldFieldValues.map(fieldValue => {
      if (fieldValue.edited) {
        delete fieldValue.edited;
      }
      return fieldValue;
    });
    return newFieldValues;
  };

  const updateCurrentFieldValues = (intentName, field) => {
    chartStore.nodes.get(intentName).intent[field] = [
      ...swapOutOldFieldValues(
        [chartStore.nodes.get(intentName).intent[field]],
        field,
      ),
    ];
  };

  const editFieldValue = (nodeId, fieldValuePosition, body, field) => {
    //TODO change after adding in selction in trainingPhrases

    const intent = chartStore.nodes.get(nodeId).intent;

    if (field === 'trainingPhrases') {
      intent.trainingPhrases[fieldValuePosition][0] = {
        text: body,
        edited: true,
      };
    }
    if (field === 'responses') {
      intent.responses[fieldValuePosition].body = body;
      intent.responses[fieldValuePosition].edited = true;
    }
  };

  const filterOutDeletedValue = (fieldValues, fieldValue_id, field) => {
    if (field === 'trainingPhrases') {
      return fieldValues.filter((fieldValue, index) => index !== fieldValue_id);
    } else {
      return fieldValues.filter(fieldValue => fieldValue.id !== fieldValue_id);
    }
  };

  const deleteFieldValue = (fieldValue_id, nodeId, field) => {
    chartStore.nodes.get(nodeId).intent[field] = filterOutDeletedValue(
      chartStore.nodes.get(nodeId).intent[field],
      fieldValue_id,
      field,
    );
  };

  const updateIntentValue = (nodeId, key, value) => {
    chartStore.nodes.get(nodeId).intent[key] = value;
  };

  return useObserver(() => (
    <DialogueElement slideRightPanel={slideRightPanel}>
      <FlowDiagram stateActions={actions} mainView={mainView} />
      <Editor
        updateIntent={updateIntent} //
        nodes={chartStore.nodes.toJSON()} //
        slideRightPanel={slideRightPanel}
        setSlideRightPanel={setSlideRightPanel}
        mainView={mainView}
        setMainView={setMainView}
        addFieldValues={addFieldValues} //
        editFieldValue={editFieldValue} //
        updateCurrentFieldValues={updateCurrentFieldValues} //
        deleteFieldValue={deleteFieldValue} //
        updateIntentValue={updateIntentValue} //
        addNewNode={addNewNode} //
        createDisplayNameArray={createDisplayNameArray} //view
      />
      <MessagePreview />
    </DialogueElement>
  ));
}

export default Dialogue;
