import styled from "styled-components";

export const NavElement = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1em;
`;

export const Tab = styled.button`
  background: none;
  border: none;
  display: flex;
  cursor: pointer;
  position: inline;
  transition: ease-out 0.1s;
  margin: 0 1em 0 0;

  &:hover {
    margin: 0 0 0 1em;
    transition: ease-in 0.05s;
    left: 0.3em;
  }
`;

export const TabImg = styled.div`
  padding: 6px;
`;

export const TabText = styled.div`
  color: ${props => (props.active ? "#35A23A" : "#b0b9cc")};
  font-family: Muli;
  font-weight: Bold;
  font-size: 23px;
  &:hover {
    .arrowOut,
    .door {
      transition: ease-in 0.05s;
      fill: gray;
    }
    transition: ease-in 0.05s;
    left: 0.3em;
  }
`;
