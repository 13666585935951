import React, { useState } from 'react';
import {
  BotVersionElement,
  BotImageContainer,
  BotImage,
  BotImageChange,
  BotName,
  BotVersion,
} from '../elements/MenuBotVersion';
import Dorris from '../images/default_formal_avatar.png';

function MenuBotVersion() {
  const [botName, setBotName] = useState('Dorris');

  return (
    <BotVersionElement>
      <BotImageContainer>
        <BotImage id="botImage" src={Dorris} alt="botImage" />
        <BotImageChange id="botImageButton">+</BotImageChange>
      </BotImageContainer>
      <BotName
        id="botName"
        value={botName}
        onChange={e => setBotName(e.target.value)}
      />
      <BotVersion>Version: 2.1 ⌄</BotVersion>
    </BotVersionElement>
  );
}

export default MenuBotVersion;
