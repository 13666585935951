import React from 'react';
import AddToValues from '../components/AddToValues';
import StoredValues from '../components/StoredValues';
import MessagePreview from '../components/MessagePreview';
import {
  ValuesContainer,
  ValuesSection,
  ValuesHeading,
  ValuesSubHeading,
  EditorPlaceholder,
} from '../elements/Values';

const Values = ({ slideRightPanel }) => {
  return (
    <ValuesContainer slideRightPanel={slideRightPanel}>
      <ValuesSection>
        <ValuesHeading data-test-id="values-heading">Values</ValuesHeading>
        <ValuesSubHeading>
          Use this section to create, edit and store value types
        </ValuesSubHeading>
        <AddToValues />
        <StoredValues />
      </ValuesSection>
      <EditorPlaceholder />
      <MessagePreview />
    </ValuesContainer>
  );
};

export default Values;
