import React from 'react';
import {
  FallbackElement,
  TitleElement,
  Fallbacks,
  ConvoPoint,
  FallbackWrapper,
} from '../elements/Fallback';
import Editor from './Editor';
import * as actions from '../utils/actions';

import MessagePreview from './MessagePreview';
import {
  AvatarImage,
  ConversationPoint as Convo,
  TitleContainer,
  MetricsContainer,
  Metric,
  MetricValue,
  MetricDescription,
} from '../elements/FlowDiagramInner';
import User from '../images/user_avatar.png';
import Dorris from '../images/default_formal_avatar.png';
import chart from '../stores/chart';
import { useObserver } from 'mobx-react';

function Fallback({
  slideMenu,
  slideRightPanel,
  setSlideRightPanel,
  setMainView,
  mainView,
}) {
  const handleChange = index => {
    // mutate selected in chart store.
    chart.selected = {
      type: index[1].type,
      id: index[0],
    };
  };
  return useObserver(() => (
    <FallbackWrapper slideRightPanel={slideRightPanel} stateActions={actions}>
      <FallbackElement>
        <TitleElement>
          <h1>Catch</h1>
          <p>
            It’s impossible to know exactly what every human will say when they
            speak with your bot. Use this section to catch users who say the
            unexpected, so your bot can respond appropriately
          </p>
        </TitleElement>
        <Fallbacks mainView={mainView}>
          {chart.getFallbacks().map(fallback => {
            const getThisId = fallback[0];
            const isSelected = fallback[0] === chart.selected.id ? true : false;
            const trainingPhrase =
              fallback[1].intent.trainingPhrases.length > 0
                ? fallback[1].intent.trainingPhrases[0][0].text
                : 'No Training Phrases';
            const response =
              fallback[1].intent.responses.length > 0
                ? fallback[1].intent.responses[0].body
                : 'No Responses';
            const sentiment = fallback[1].metrics.sentiment_average;
            const dropOff = fallback[1].metrics.dropoff_rate;
            return (
              <ConvoPoint
                onClick={() => {
                  handleChange(fallback);
                }}
                isSelected={isSelected}
                mainView={mainView}
                setMainView={setMainView}
                key={getThisId}
              >
                <Convo>
                  <TitleContainer user="user">
                    {fallback[1].intent.displayName}
                  </TitleContainer>
                  <AvatarImage id="userImage" src={User} alt="User Image" />
                  <TitleContainer user="user">{trainingPhrase}</TitleContainer>
                  <AvatarImage id="userImage" src={Dorris} alt="User Image" />
                  <TitleContainer user="bot">{response}</TitleContainer>
                  <MetricsContainer>
                    <Metric>
                      <MetricValue color="#DE5E65" fontSize="1.25rem">
                        {sentiment}
                      </MetricValue>
                      <MetricDescription>Sentiment</MetricDescription>
                    </Metric>
                    <Metric>
                      <MetricValue color="#2E6488" fontSize="1.25rem">
                        {dropOff}
                      </MetricValue>
                      <MetricDescription>Drop Off</MetricDescription>
                    </Metric>
                  </MetricsContainer>
                </Convo>
              </ConvoPoint>
            );
          })}
        </Fallbacks>
      </FallbackElement>
      <Editor
        slideRightPanel={slideRightPanel}
        setSlideRightPanel={setSlideRightPanel}
        mainView={mainView}
      />
      <MessagePreview />
    </FallbackWrapper>
  ));
}

export default Fallback;
