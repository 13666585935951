import styled, { css } from 'styled-components';
const highlightOnSelected = props =>
  props.isSelected &&
  css`
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border: solid 1px #da6c8c;
  `;

export const ConversationPoint = styled.div`
  box-shadow: 0px 3px 10px rgba(219, 219, 219, 0.25);
  transition: 0.3 ease-in;
  background: #fff;
  border: solid #fff 1px;
  border-radius: 3px;
  position: absolute;
  min-height: 50px;
  min-width: 150px;
  ${highlightOnSelected}
`;

export const PortIn = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -4px 3px;
  background: ${props => props.bgColor};
`;

export const PortOut = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #49a6d2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.bgColor};
`;
