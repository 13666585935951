import React from 'react';

export default props => {
  let { size = '25px', fill = '#B0B9CC' } = props;
  return (
    <svg viewBox="0 0 28 28" height={size} width={size}>
      <path
        fill={fill}
        d="M13.777,0C7.108,0,1.701,5.407,1.701,12.076c0,3.45,1.454,6.553,3.773,8.752L5.07,27.555l5.736-3.787
		c0.952,0.241,1.944,0.383,2.971,0.383c6.67,0,12.076-5.406,12.076-12.076C25.854,5.407,20.447,0,13.777,0z M9.151,10.694v2.423
		h-2.61v-2.009c0-0.74,0.154-1.324,0.462-1.752C7.312,8.928,7.839,8.55,8.587,8.222L9.151,9.29c-0.46,0.216-0.778,0.431-0.954,0.645
		c-0.176,0.214-0.274,0.467-0.294,0.759H9.151z M13.154,10.694v2.423h-2.61v-2.009c0-0.74,0.154-1.324,0.462-1.752
		c0.308-0.428,0.836-0.806,1.584-1.134l0.564,1.068c-0.46,0.216-0.778,0.431-0.954,0.645c-0.176,0.214-0.274,0.467-0.294,0.759
		H13.154z M17.01,12.68c0,0.74-0.153,1.324-0.461,1.752c-0.309,0.428-0.836,0.807-1.584,1.135L14.4,14.498
		c0.46-0.217,0.777-0.432,0.954-0.645c0.176-0.215,0.274-0.468,0.294-0.76H14.4V10.67h2.609V12.68z M21.014,12.68
		c0,0.74-0.154,1.324-0.463,1.752c-0.308,0.428-0.836,0.807-1.584,1.135l-0.563-1.068c0.46-0.217,0.778-0.432,0.954-0.645
		c0.176-0.215,0.273-0.468,0.294-0.76h-1.249V10.67h2.609v2.01H21.014z"
      />
    </svg>
  );
};
