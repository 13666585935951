import styled from 'styled-components';
import { Input, Button } from '@northcoders-dev-team/dorris-design';

export const EditorElement = styled.div`
  background-color: rgb(180, 224, 220);
  grid-area: ${props =>
    props.mainView === 'editor' ? 'primary' : 'secondary'};
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 2rem;
  box-shadow: -5px 2px 5px rgba(198, 198, 198, 0.25);
  overflow-y: scroll;
  z-index: 10;
  width: 100%;
`;

export const PersonaSelector = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 600;
`;

export const ConversationTitle = styled.input`
  color: #7a7a7a;
  font-size: 14px;
  padding: 0.25rem 0;
  text-align: left;
  font-weight: 600;
  border: none;
  border-bottom: 1px solid #c3c6cd;
  width: 90%;
`;

export const ListInput = styled(Input)`
  padding: 0.5em;
  border: none;
  background: none;
  box-shadow: none;
  margin: 0;
  font-size: 1rem;
`;

export const MainViewSwitch = styled.button`
  text-align: center;
  position: absolute;
  right: ${props => props.slideRightPanel};
  border: none;
  margin-top: 0.7em;
  background-color: white;
  border-radius: 10px 0 0 10px;
  padding: 0 0.2em 0 0.2em;
  font-size: 1.4em;
  color: #b0b9cc;
  box-shadow: -5px 2px 5px rgba(198, 198, 198, 0.3);
  width: 40px;
  transition: ease-out 0.1s;
  cursor: ew-resize;
  z-index: 80;
  :hover {
    transition: ease-in 0.1s;
    color: gray;
  }
`;

export const SaveButton = styled(Button)`
  text-align: center;
  position: absolute;
  bottom: 0.5em;
  right: ${props => `calc(${props.slideRightPanel} + 10px)`};
  border: none;
  margin-top: 0.7em;
  border-radius: 10px;
  min-width: 80px;
  transition: ease-out 0.1s;
  cursor: ew-resize;
  z-index: 100;
`;

export const ResetButton = styled(Button)`
  background-color: crimson !important;
  text-align: center;
  position: absolute;
  bottom: 0.5em;
  right: ${props => `calc(${props.slideRightPanel} + 250px)`};
  border: none;
  margin-top: 0.7em;
  border-radius: 10px;
  min-width: 80px;
  transition: ease-out 0.1s;
  cursor: ew-resize;
  z-index: 100;
`;

export const AddPointButton = styled(SaveButton)`
  right: ${props => `calc(${props.slideRightPanel} + 150px)`};
  min-width: 20px;
`;

export const AddConvPoint = styled.button``;
