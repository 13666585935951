import { types } from 'mobx-state-tree';
import Intent from './Intent';

const ConversationNode = types.model('ConversationNode', {
  id: types.identifier,
  type: types.string,
  intent: Intent,
  position: types.model({
    id: types.maybe(types.string),
    x: types.maybe(types.number),
    y: types.maybe(types.number),
  }),
  ports: types.model({
    portInput: types.model({
      id: types.string,
      type: types.string,
      properties: types.model({ type: types.string }),
      position: types.optional(
        types.model({
          x: types.number,
          y: types.number,
          id: types.maybe(types.string),
        }),
        { x: 138, y: 0 },
        [null, undefined],
      ),
    }),
    portOutput: types.model({
      id: types.string,
      type: types.string,
      properties: types.model({ type: types.string }),
      position: types.optional(
        types.model({
          x: types.number,
          y: types.number,
          id: types.maybe(types.string),
        }),
        { x: 137.5, y: 219.5 },
        [null, undefined],
      ),
    }),
  }),
  metrics: types.optional(
    types.model({
      sentiment_average: types.string,
      dropoff_rate: types.string,
    }),
    { sentiment_average: '0', dropoff_rate: '0%' },
    [null, undefined],
  ),
  size: types.maybeNull(
    types.model({
      width: types.number,
      height: types.number,
    }),
  ),
});

export default ConversationNode;
