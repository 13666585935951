import styled from 'styled-components';

export const FlowDiagramElement = styled.div`
  background-color: #eeeeee;
  grid-area: ${props =>
    props.mainView === 'flowDiagram' ? 'primary' : 'secondary'};
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Background = styled.div`
  background-color: #eeeeee;
`;
