import React from 'react';
import {
  MenuUserInfoElement,
  UserImage,
  UserName,
  LogoutButton,
} from '../elements/MenuUserInfo';
import LogoutSVG from '../svgs/logout';
import userAvatar from '../images/user_avatar.png';

function MenuUserInfo() {
  const firstName = 'Tommy';
  const surname = 'Luff';

  return (
    <MenuUserInfoElement>
      <UserImage id="userImage" src={userAvatar} />
      <UserName id="userName">
        <span>{firstName}</span>
        <span>{surname}</span>
      </UserName>
      <LogoutButton id="logoutCont">
        <LogoutSVG id="logout" />
      </LogoutButton>
    </MenuUserInfoElement>
  );
}

export default MenuUserInfo;
