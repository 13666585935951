import React from 'react';
import { SlideButton, MenuElement, CompanyImg } from '../elements/Menu';
import MenuBotVersion from './MenuBotVersion';
import MenuNav from './MenuNav';
import MenuUserInfo from './MenuUserInfo';
import companyLogo from '../images/compareCover_logo.png';

function Menu({ slideMenu, setSlideMenu }) {
  return (
    <MenuElement>
      <SlideButton
        id="menuSlide"
        slideMenu={slideMenu}
        onClick={() => {
          if (slideMenu === '18vw') {
            setSlideMenu('0');
          } else {
            setSlideMenu('18vw');
          }
        }}
      >
        ≡
      </SlideButton>
      {slideMenu === '0' ? (
        <div></div>
      ) : (
        <CompanyImg id="companyImg" src={companyLogo} alt="CompanyLogo" />
      )}
      {slideMenu === '0' ? <div></div> : <MenuBotVersion />}
      {slideMenu === '0' ? <div></div> : <MenuNav />}
      {slideMenu === '0' ? <div></div> : <MenuUserInfo />}
    </MenuElement>
  );
}

export default Menu;
