import React from 'react';
import { Formik } from 'formik';
import { Form, Input } from '../elements/AddToList';

const AddToSynonyms = ({ onSubmit, ...otherProps }) => {
  return (
    <Formik initialValues={{ newSynonym: '' }} onSubmit={onSubmit}>
      {({ values, handleChange, handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit} data-test-id="addSynForm">
            <Input
              type="text"
              name="newSynonym"
              value={values.newSynonym}
              onChange={handleChange}
              placeholder={'Enter a new Synonym...'}
              {...otherProps}
            ></Input>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddToSynonyms;
