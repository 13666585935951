import React from 'react';
import { Formik, Form } from 'formik';
import {
  ReferenceNameInputFormWrapper,
  ReferenceNameInput,
} from '../elements/EditEntity';

// enableReinitialize=true refreshes initial values passed down from MST

const EditEntity = ({
  onSubmit,
  initialValue = '',
  placeholder = '',
  ...otherProps
}) => {
  return (
    <Formik
      initialValues={{ value: initialValue }}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ values, handleChange, handleSubmit }) => {
        return (
          <ReferenceNameInputFormWrapper>
            <Form onSubmit={handleSubmit}>
              <ReferenceNameInput
                type="text"
                name="value"
                value={values.value}
                onChange={handleChange}
                placeholder={placeholder}
                autoComplete="off"
                {...otherProps}
              ></ReferenceNameInput>
            </Form>
          </ReferenceNameInputFormWrapper>
        );
      }}
    </Formik>
  );
};

export default EditEntity;
