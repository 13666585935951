import styled from 'styled-components';

export const PreviewElement = styled.section`
  background-color: #fff;
  border-top: 1px solid #c3c6cd;
  grid-area: preview;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: -5px 2px 5px rgba(198, 198, 198, 0.25);
  z-index: 10;
`;

export const MessagesContainer = styled.div`
  overflow: scroll;
  padding: 1.5vh;
`;

export const LoadingCircle = styled.span`
  font-size: 1.2em;
  animation-delay: ${props => props.order};
  animation-duration: 0.7s;
  animation-name: slidein;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  @keyframes slidein {
    from {
      font-size: 1em;
      opacity: 1;
    }
    to {
      font-size: 0.6em;
      opacity: 0.6;
    }
  }
`;
