import React from 'react';

export default props => {
  let { size = '25px', fill = '#B0B9CC' } = props;
  return (
    <svg viewBox="0 0 463 463" height={size} width={size}>
      <path
        fill={fill}
        d="M463,231.5c0-12.958-10.542-23.5-23.5-23.5H255V71.5c0-12.958-10.542-23.5-23.5-23.5S208,58.542,208,71.5V208H23.5
   C10.542,208,0,218.542,0,231.5c0,8.481,4.517,15.927,11.271,20.059C9.196,255.061,8,259.143,8,263.5
   c0,8.481,4.517,15.927,11.271,20.059C17.196,287.061,16,291.143,16,295.5c0,8.481,4.517,15.927,11.271,20.059
   C25.196,319.061,24,323.143,24,327.5c0,8.481,4.517,15.927,11.271,20.059C33.196,351.061,32,355.143,32,359.5
   c0,8.481,4.517,15.927,11.271,20.059C41.196,383.061,40,387.143,40,391.5c0,12.958,10.542,23.5,23.5,23.5h336
   c12.958,0,23.5-10.542,23.5-23.5c0-4.357-1.196-8.439-3.271-11.941C426.483,375.427,431,367.981,431,359.5
   c0-3.755-0.89-7.305-2.463-10.456c0.089-0.081,0.181-0.155,0.266-0.241c0.99-0.99,1.637-2.19,1.958-3.455
   c5.037-4.313,8.239-10.712,8.239-17.848c0-4.357-1.196-8.439-3.271-11.941C442.483,311.427,447,303.981,447,295.5
   c0-4.357-1.196-8.439-3.271-11.941C450.483,279.427,455,271.981,455,263.5c0-4.357-1.196-8.439-3.271-11.941
   C458.483,247.427,463,239.981,463,231.5z M448,231.5c0,4.687-3.813,8.5-8.5,8.5h-28.894l-17-17H439.5
   C444.187,223,448,226.813,448,231.5z M440,263.5c0,4.687-3.813,8.5-8.5,8.5h-36.894l-17-17H431.5C436.187,255,440,258.813,440,263.5
   z M432,295.5c0,4.687-3.813,8.5-8.5,8.5h-52.894l-17-17H423.5C428.187,287,432,290.813,432,295.5z M424,327.5
   c0,1.677-0.495,3.238-1.337,4.557L409.606,319h5.894C420.187,319,424,322.813,424,327.5z M306.606,400l-17-17h58.787l17,17H306.606z
    M226.606,400l-17-17h58.787l17,17H226.606z M146.606,400l-17-17h58.787l17,17H146.606z M90.606,368l-17-17h58.787l17,17H90.606z
    M55.5,368c-4.687,0-8.5-3.813-8.5-8.5c0-3.754,2.448-6.941,5.83-8.064L69.394,368H55.5z M47.5,336c-4.687,0-8.5-3.813-8.5-8.5
   s3.813-8.5,8.5-8.5h20.894l17,17H47.5z M39.5,304c-4.687,0-8.5-3.813-8.5-8.5s3.813-8.5,8.5-8.5h52.894l17,17H39.5z M31.5,272
   c-4.687,0-8.5-3.813-8.5-8.5s3.813-8.5,8.5-8.5h4.894l17,17H31.5z M132.394,223l17,17H90.606l-17-17H132.394z M196.394,255l17,17
   h-58.787l-17-17H196.394z M276.394,255l17,17h-58.787l-17-17H276.394z M372.394,223l17,17h-58.787l-17-17H372.394z M356.394,255
   l17,17h-58.787l-17-17H356.394z M388.394,319l17,17h-58.787l-17-17H388.394z M250.606,368l-17-17h58.787l17,17H250.606z
    M170.606,368l-17-17h58.787l17,17H170.606z M106.606,336l-17-17h58.787l17,17H106.606z M172.394,287l17,17h-58.787l-17-17H172.394z
    M252.394,287l17,17h-58.787l-17-17H252.394z M186.606,336l-17-17h58.787l17,17H186.606z M266.606,336l-17-17h58.787l17,17H266.606z
    M290.606,304l-17-17h58.787l17,17H290.606z M116.394,255l17,17H74.606l-17-17H116.394z M330.606,368l-17-17h58.787l17,17H330.606z
    M410.17,367.564L393.606,351H407.5c4.687,0,8.5,3.813,8.5,8.5C416,363.254,413.552,366.441,410.17,367.564z M292.394,223l17,17
   h-55.991c1.027-2.638,1.597-5.503,1.597-8.5V223H292.394z M223,71.5c0-4.687,3.813-8.5,8.5-8.5s8.5,3.813,8.5,8.5v160
   c0,4.687-3.813,8.5-8.5,8.5s-8.5-3.813-8.5-8.5V71.5z M209.597,240h-38.991l-17-17H208v8.5C208,234.497,208.57,237.362,209.597,240z
    M23.5,223h28.894l17,17H23.5c-4.687,0-8.5-3.813-8.5-8.5S18.813,223,23.5,223z M55,391.5c0-4.687,3.813-8.5,8.5-8.5h44.894l17,17
   H63.5C58.813,400,55,396.187,55,391.5z M399.5,400h-12.894l-17-17H399.5c4.687,0,8.5,3.813,8.5,8.5S404.187,400,399.5,400z"
      />
    </svg>
  );
};
