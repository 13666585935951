import React from 'react';
import ChatCircle from '../svgs/chatCircle';
import Basket from '../svgs/basket';
import { PortIn, PortOut } from '../elements/FlowDiagramOuter';

const PortInput = ({ port, config }) => {
  switch (port.properties.type) {
    case 'conversationPoint-input':
      return (
        <PortIn bgColor={'#da6c8c'} data-test-id="port-in">
          <ChatCircle fill="#ffffff" size="18" />
        </PortIn>
      );
    case 'conversationPoint-output':
      return <PortOut bgColor={'#49A6D2'} data-test-id="port-out"></PortOut>;
    case 'conversationPrompt-input':
      return (
        <PortIn bgColor={'#da6c8c'} data-test-id="port-in">
          >
          <ChatCircle fill="#ffffff" size="18" />
        </PortIn>
      );
    case 'conversationPrompt-output':
      return <PortOut bgColor={'#F15036'} data-test-id="port-out"></PortOut>;
    case 'value-input':
      return (
        <PortIn bgColor={'#F15036'} data-test-id="port-in">
          ><Basket fill="#fff" size="20"></Basket>
        </PortIn>
      );
    case 'value-output':
      return <PortOut bgColor={'#da6c8c'} data-test-id="port-out"></PortOut>;

    default:
      return null;
  }
};

export default PortInput;
