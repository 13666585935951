import { types } from 'mobx-state-tree';
import TrainingPhrase from './TrainingPhrase';
import Response from './Response';
import Parameter from './Parameter';

const Intent = types
  .model('Intent', {
    name: types.string,
    displayName: types.string,
    priority: types.number,
    isFallback: types.maybeNull(types.boolean),
    webhookState: types.maybe(types.union(types.string, types.number)),
    action: types.maybe(types.string),
    resetContexts: types.maybe(types.boolean),
    rootFollowupIntentName: types.maybe(types.string),
    parentFollowupIntentName: types.maybe(types.string),
    mlDisabled: types.maybe(types.boolean),
    events: types.array(types.string),
    messages: types.array(
      types.model({
        platform: types.string,
        message: types.string,
        text: types.maybeNull(
          types.model({
            text: types.maybeNull(types.array(types.string)),
          }),
        ),
      }),
    ),
    defaultResponsePlatforms: types.array(types.string),
    followupIntentInfo: types.array(types.string),
    trainingPhrases: types.optional(
      types.array(TrainingPhrase),
      [],
      [null, undefined],
    ),
    responses: types.optional(types.array(Response), [], [null, undefined]),
    parameters: types.optional(types.array(Parameter), [], [null, undefined]),
    inputContextNames: types.array(types.string),
    outputContexts: types.array(
      types.model({
        name: types.string,
        lifespanCount: types.number,
        parameters: types.maybeNull(types.array(types.model({}))),
      }),
    ),
    position_id: types.maybe(types.string),
    x: types.maybe(types.number),
    y: types.maybe(types.number),
  })
  .actions(self => ({
    setResponsesComplete() {
      self.responses.forEach(response => {
        response.edited = null;
        response.isNew = null;
      });
    },
  }))
  .views(self => ({
    getListResponses() {
      return self.responses;
    },
    //test
    getApiFormattedIntent() {
      const {
        displayName: display_name,
        trainingPhrases: training_phrases,
        responses,
        position_id,
        inputContextNames: input_context_names,
        x,
        y,
        ...restOfIntent
      } = self;
      return {
        ...restOfIntent,
        display_name,
        training_phrases,
        input_context_names,
      };
    },
    isResponseEmpty() {
      return self.responses.some(response => response.body === '');
    },
    getApiFormattedNewResponses() {
      return self.responses
        .filter(response => response.isNew)
        .map(({ id, body, intent_id, persona_id }) => ({
          id,
          body,
          intent_id,
          persona_id,
        }));
    },
    getApiFormattedEditedResponses() {
      return self.responses
        .filter(response => !response.isNew && response.edited)
        .map(({ id, body, intent_id, persona_id }) => ({
          id,
          body,
          intent_id,
          persona_id,
        }));
    },
    areResponsesEdited() {
      return self.responses.some(response => {
        return response.edited;
      });
    },
  }));

export default Intent;
