import styled from "styled-components";

export const BotVersionElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
`;

export const BotImageContainer = styled.div`
  width: 6em;
`;

export const BotImage = styled.img`
  width: 100%;
  border-radius: 50px;
  box-shadow: 0px 2px 5px rgba(198, 198, 198, 0.25);
`;
export const BotImageChange = styled.button`
  position: relative;
  width: auto;
  bottom: 1.5em;
  font-size: 1em;
  border: none;
  color: white;
  font-weight: bold;
  background-color: #b0b9cc;
  border-radius: 50px;
  box-shadow: 0px 2px 5px rgba(198, 198, 198, 0.25);
  transition: ease-out 0.05s;
  cursor: pointer;

  &:hover {
    color: #b0b9cc;
    background-color: white;
    transition: ease-in 0.05s;
    box-shadow: 0px 2px 5px rgba(198, 198, 198, 0.4);
  }
`;

export const BotName = styled.input`
  font-family: "Muli";
  font-style: normal;
  font-weight: bold;
  width: 100%;
  color: #b0b9cc;
  border: none;
  text-align: center;
  font-size: 1.5em;
  margin: 0.5em;
`;

export const BotVersion = styled.div`
  font-family: "Muli";
  font-style: normal;
  font-weight: bold;
  color: #b0b9cc;
`;
