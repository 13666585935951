import React, { Fragment } from 'react';
import { Formik, FieldArray } from 'formik';
import AddToSynonyms from './AddToSynonyms';
import {
  Form,
  AddValuesWrapper,
  NameInput,
  Select,
  NewValues,
  ValueLabel,
  ReferenceNameWrapper,
  ReferenceNameInput,
  SynonymList,
  SynonymListItem,
  DeleteIcon,
} from '../elements/AddToValues';

import { Button } from '@northcoders-dev-team/dorris-design';

import * as api from '../utils/api';

import entityTypesStore from '../stores/entityTypes';

const AddToValues = props => {
  return (
    <Formik
      initialValues={{
        displayName: '',
        entities: [
          {
            id: '1',
            synonyms: [],
            value: '',
          },
        ],
      }}
      onSubmit={async (values, { resetForm }) => {
        const entityTypeToPost = {
          displayName: values.displayName,
          entities: values.entities.map(entity => ({
            synonyms: entity.synonyms,
            value: entity.value,
          })),
        };

        const newEntityType = await api.postEntityType(entityTypeToPost);
        // mutate MST, and re-render
        entityTypesStore.entityTypes.unshift(newEntityType);
        resetForm();
      }}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <h2>Create New Value</h2>
            <AddValuesWrapper>
              <NameInput
                data-test-id="entityType-displayName"
                type="text"
                name="displayName"
                placeholder="Add your values name here..."
                onChange={handleChange}
                value={values.displayName}
                autoComplete="off"
              />
              <Select>
                <option>Rules (coming soon...)</option>
              </Select>
              <NewValues>
                <ValueLabel>Reference Name</ValueLabel>
                <ValueLabel>Add Synonyms here</ValueLabel>
                {values.entities.map((entity, index) => (
                  <Fragment key={entity.id}>
                    <ReferenceNameWrapper>
                      <ReferenceNameInput
                        data-test-id="new-entity-input"
                        name={`entities[${index}].value`}
                        type="text"
                        value={entity.value}
                        onChange={handleChange}
                      />
                      <DeleteIcon
                        size="15px"
                        fill="#DA6C8C"
                        onClick={() =>
                          setFieldValue(
                            'entities',
                            values.entities.filter(
                              currentEntity => currentEntity !== entity,
                            ),
                          )
                        }
                      />
                    </ReferenceNameWrapper>

                    <FieldArray name={`entities[${index}].synonyms`}>
                      {({ push, remove }) => {
                        return (
                          <SynonymList data-test-id="add-synonym-list">
                            {entity.synonyms.map((synonym, synindex) => (
                              <>
                                <SynonymListItem key={synonym}>
                                  {synonym}
                                  <DeleteIcon
                                    data-test-id={`add-synonyms-delete-${synonym}`}
                                    size="15px"
                                    fill="#DA6C8C"
                                    onClick={() => remove(synindex)}
                                  />
                                </SynonymListItem>
                              </>
                            ))}
                            <AddToSynonyms
                              data-test-id="new-synonym-input"
                              onSubmit={(values, { resetForm }) => {
                                push(values.newSynonym);
                                resetForm(); // do not submit
                              }}
                            />
                          </SynonymList>
                        );
                      }}
                    </FieldArray>
                  </Fragment>
                ))}
              </NewValues>
              {/* add new empty row by adding new blank entity to state */}
              <Button
                type="button"
                onClick={() =>
                  setFieldValue('entities', [
                    ...values.entities,
                    {
                      id: '',
                      synonyms: [],
                      value: '',
                    },
                  ])
                }
              >
                + Add New Row
              </Button>
              <Button type="submit">Store Value</Button>
            </AddValuesWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddToValues;
