import { types } from 'mobx-state-tree';

const Training = types
  .model('Training', {
    isTraining: types.boolean,
  })
  .actions(self => ({
    startTimeout() {
      self.isTraining = true;
      setTimeout(() => {
        self.isTraining = false;
      }, 5000);
    },
  }));

export default Training;
