import styled from 'styled-components';

export const SlideButton = styled.button`
  width: 1em;
  position: absolute;
  top: 1em;
  color: #b0b9cc;
  border-radius: 0 10px 10px 0;
  padding: 0 1.4em .2em 0.4em;
  font-size: 1.4em;
  font-weight: bold;
  left: ${props => props.slideMenu};
  border: none;
  box-shadow: 5px 2px 5px rgba(198, 198, 198, 0.25);
  overflow: hidden;
  transition: ease-out 0.1s;
  cursor: pointer;
  z-index: 100;
  :hover {
    padding: 0 1.4em .2em 0.4em;
    color: gray;
    transition: ease-in 0.1s;
  }
`;

export const MenuElement = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  grid-area: menu;
  box-shadow: 0px 2px 10px rgba(198, 198, 198, 0.25);
  z-index: 1;
`;

export const CompanyImg = styled.img`
  height: 1.5em;
  margin: 2em 1em 1em 1em;
`;
