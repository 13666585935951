import styled, { css } from 'styled-components';
const highlightOnSelected = props =>
  props.isSelected &&
  css`
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border: solid 1px #da6c8c;
  `;

export const FallbackElement = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
`;
export const TitleElement = styled.div`
  width: 90%;
  /* height: 20%; */
  color: #777;
  margin: 0 auto;
`;
export const Fallbacks = styled.ul`
  list-style: none;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: 1256px) {
    justify-content: center;
  }
`;
export const ConvoPoint = styled.li`
  box-shadow: 0px 3px 10px rgba(219, 219, 219, 0.25);
  transition: 0.3 ease-in;
  background: #fff;
  border: solid #fff 1px;
  border-radius: 3px;
  min-width: 150px;
  margin-bottom: 1em;
  ${highlightOnSelected};
  @media (min-width: 850px) {
    &:not(:last-of-type) {
      margin-right: 2em;
    }
  }
  @media (max-width: 1256px) {
    /* &:last-of-type {
      margin-right: 2em;
    } */
  }
`;

export const FallbackWrapper = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  display: grid;
  grid-area: dashboard;
  grid-template-columns: auto ${props => props.slideRightPanel};
  grid-template-rows: 60vh 40vh;
  grid-template-areas:
    'primary secondary'
    'primary preview';
`;
