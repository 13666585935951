import React, { useState } from 'react';
import {
  ConversationPoint,
  AvatarImage,
  TitleContainer,
  DeleteButton,
  ButtonText,
  QueryDeleteButton,
  QueryDeleteButtonArea,
  MetricsContainer,
  Metric,
  MetricValue,
  MetricDescription,
  ValueName,
  ValuePrompt,
  ValuesContainer,
  ValueBorder,
} from '../elements/FlowDiagramInner';
import Dorris from '../images/default_formal_avatar.png';
import User from '../images/user_avatar.png';
import chartStore from '../stores/chart';

const FlowDiagramInner = ({ node, config }) => {
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  return (
    <ConversationPoint>
      {chartStore.selected.id === node.id ? (
        <DeleteButton
          data-test-id="delete-node-button"
          className="deleteButton"
          onClick={() => {
            setDeleteConfirm(true);
          }}
        >
          <ButtonText>+</ButtonText>
        </DeleteButton>
      ) : (
        <></>
      )}

      {deleteConfirm ? (
        <>
          <TitleContainer>
            Do you want to delete this Conversation Point?
          </TitleContainer>
          <TitleContainer>
            <QueryDeleteButtonArea>
              <QueryDeleteButton
                onClick={() => {
                  const intentName = node.id.match(/([0-9a-z-]+)$/gi)[0];
                  config.onNodeDelete(node.id, intentName);
                }}
              >
                Confirm
              </QueryDeleteButton>
              <QueryDeleteButton onClick={() => setDeleteConfirm(false)}>
                Cancel
              </QueryDeleteButton>
            </QueryDeleteButtonArea>
          </TitleContainer>
        </>
      ) : (
        <>
          <TitleContainer
            user="user"
            data-test-id={`chart-${node.intent.displayName}`}
          >
            <AvatarImage id="userImage" src={User} alt="User Image" />
            {node.intent.trainingPhrases.length
              ? node.intent.trainingPhrases[0][0].text
              : node.intent.displayName}
          </TitleContainer>
          <TitleContainer>
            {node.intent.parameters &&
              node.intent.parameters.map((parameter, index) => {
                return (
                  <ValuesContainer key={parameter.name}>
                    <ValueName>{parameter.displayName}</ValueName>
                    <ValuePrompt>{parameter.prompts[0]}</ValuePrompt>
                    <ValueBorder
                      isLast={index + 1 === node.intent.parameters.length}
                    />
                  </ValuesContainer>
                );
              })}
          </TitleContainer>
          <TitleContainer user="bot">
            <AvatarImage id="botImage" src={Dorris} alt="Bot Image" />

            {node.intent.responses.length
              ? node.intent.responses[0].body
              : 'No Responses'}
          </TitleContainer>

          <MetricsContainer
            data-test-id={`chart-${node.intent.displayName}-metrics`}
          >
            <Metric>
              <MetricValue
                data-test-id="value-sentiment-score"
                color="#DE5E65"
                fontSize="1.25rem"
              >
                {node.metrics ? node.metrics.sentiment_average : '0.0'}
              </MetricValue>
              <MetricDescription fontSize="0.75rem">
                Sentiment Score
              </MetricDescription>
            </Metric>
            <Metric>
              <MetricValue
                data-test-id="value-drop-off-rate"
                color="#2E6488"
                fontSize="1.25rem"
              >
                {node.metrics ? node.metrics.dropoff_rate : '0.0%'}
              </MetricValue>
              <MetricDescription fontSize="0.75rem">
                Drop-off Rate
              </MetricDescription>
            </Metric>
          </MetricsContainer>
        </>
      )}
    </ConversationPoint>
  );
};

export default FlowDiagramInner;
