import styled, { css } from 'styled-components';
import { Button } from '@northcoders-dev-team/dorris-design';

export const ModalSection = styled.section`
  position: absolute;
  left: 20vw;
  width: 60vw;
  top: 10em;
  padding: 2em;
  background-color: white;
  z-index: 2;
`;

export const UpdateError = styled.p`
  color: #f80200;
  text-align: center;
  margin: 0;
  transition: 300ms;
  font-weight: bold;

  ${props =>
    !props.errMsg &&
    css`
      visibility: hidden;
    `};
`;

export const UpdateParameterButton = styled(Button)`
  min-width: 100px;
  max-width: 140px;
  margin: 0.8em 0 0.8em 0.5em !important;
`;

export const ParameterTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ParameterTypeSelect = styled.select`
  border: none;
  margin: 0;
  background-color: rgb(0, 0, 0, 0);
  border: solid 1px #eeeeee;
  font-size: 1rem;
  font-family: 'Muli';
  color: #7a7a7a;
  margin-top: 0.2em;
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  padding: 2px;
`;

export const RequiredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RequiredSwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RequiredOption = styled.span`
  font-size: 0.7rem;
  font-family: 'Muli';
  color: #7a7a7a;
`;

export const ModalSubheader = styled.span`
  font-size: 0.8rem;
  font-family: 'Muli';
  color: #7a7a7a;
  font-weight: bold;
  margin-top: 1rem;
`;

export const ModalTitle = styled.input`
  font-size: 1.5rem;
  font-family: 'Muli';
  color: #7a7a7a;
  font-weight: 400;
  margin-top: 1rem;
  border: none;
  margin-left: 0.5em;
`;

export const ButtonAlign = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
`;

export const ParameterOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 5px;
`;

export const PromptInput = styled.input`
  padding: 0.3em;
  width: 100%;
  border: none;
  background-color: rgb(0, 0, 0, 0);
  transition: ease-in 0.3s;
  &:hover {
    transition: ease-out 0.3s;
  }
`;

export const DeleteWrapper = styled.div`
  display: flex;
  align-items: center;
  float: right;
`;

export const CenterStyle = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const PromptsNotRequiredMsg = styled.p`
  font-size: 1rem;
  font-family: 'Muli';
  color: #7a7a7a;
  font-weight: 400;
  text-align: center;
`;
