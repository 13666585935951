import React, { useState } from 'react';
import Switch from '@material-ui/core/Switch';

export default function Switches() {
  const [checked, setChecked] = useState({
    checked: false,
  });

  const handleChange = name => event => {
    setChecked({ ...checked, [name]: event.target.checked });
  };

  return (
    <div>
      <Switch
        color="default"
        checked={checked.checked}
        onChange={handleChange('checked')}
        value="checked"
        inputProps={{ 'aria-label': 'checkbox' }}
      />
    </div>
  );
}
