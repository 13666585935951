import styled from 'styled-components';
import { Button } from '@northcoders-dev-team/dorris-design';

export const ConversationPoint = styled.div`
  margin: 1em;
  color: #bababa;
  padding: 0.2em;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 1.1em;
  line-height: 16px;
  width: 240px;
  text-align: center;
  p {
    text-align: center;
  }
`;

export const AvatarImage = styled.img`
  width: 20px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
`;

export const TitleContainer = styled.div`
  margin: 8% auto 6% auto;
  color: ${props => (props.user === 'bot' ? '#A1C1D0' : '#DA6C8C')};
  width: 240px;
  border-bottom: ${props =>
    props.user === 'user' ? '1px solid #eeeeee' : null};

  padding-bottom: ${props => (props.user === 'user' ? '8px;' : null)};
`;

export const DeleteButton = styled.button`
  color: #e71d36;
  opacity: 0.6;
  position: absolute;
  top: -5px;
  left: -35px;
  display: flex;
  align-items: center;
  border: none;
  font-size: 2em;
  background-color: rgb(0, 0, 0, 0);
  transition: ease-in 0.2s;
  :hover {
    opacity: 1;
    transition: ease-out 0.2s;
  }
`;

export const ButtonText = styled.div`
  transform: rotate(45deg);
`;

export const QueryDeleteButtonArea = styled.span`
  display: flex;
  justify-content: space-around;
`;

export const QueryDeleteButton = styled(Button)`
  font-size: 0.8em;
`;

export const MetricsContainer = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid #eeeeee;
  padding-top: 1.5rem;
`;

export const Metric = styled.div`
  width: 50%;
`;

export const MetricValue = styled.div`
  margin-bottom: 0.5rem;
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
`;

export const MetricDescription = styled.p`
  margin: 0;
  font-size: ${props => props.fontSize};
`;

export const ValueName = styled.p`
  margin: 4% auto;
  color: #f15036;
`;

export const ValuePrompt = styled.p`
  margin: 4% 0;
  text-align: left !important;
  color: #ffb1a5;
`;
export const ValuesContainer = styled.div``;

export const ValueBorder = styled.div`
  border-bottom: ${props => (props.isLast ? 'none' : '1px dashed #A1C1D0')};
  width: 70%;
  margin: 0 15%;
`;
