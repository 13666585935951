import styled from 'styled-components';

export const DialogueElement = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-area: dashboard;
  grid-template-columns: auto ${props => props.slideRightPanel};
  grid-template-rows: 60vh 40vh;
  grid-template-areas:
    'primary secondary'
    'primary preview';
`;
