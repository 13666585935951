import React, { useState } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cloneDeep from 'lodash.clonedeep';
import { useObserver } from 'mobx-react-lite';

import entityTypesStore from '../stores/entityTypes';
import AddToSynonyms from './AddToSynonyms';
import EditEntity from './EditEntity';
import {
  StoredValuesList,
  StoredValuesWrapper,
  DisplayName,
  ReferenceNameWrapper,
  SynonymList,
  SynonymListItem,
  DeleteIcon,
} from '../elements/StoredValues';
import * as api from '../utils/api';

const StoredValues = () => {
  const [expanded, setExpanded] = useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return useObserver(() => (
    <>
      <h2>Stored Values</h2>

      {entityTypesStore.entityTypes.map((entityType, index) => (
        <ExpansionPanel
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          key={entityType.displayName}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <DeleteIcon
              data-test-id={`entityType-${entityType.getID()}-delete`}
              size="15px"
              fill="#DA6C8C"
              onClick={async ev => {
                ev.stopPropagation(); // do not bubble up to expansion panel
                await api.deleteEntityType(entityType.getID());
                entityTypesStore.entityTypes.remove(entityType);
              }}
            />
            <DisplayName data-test-id={`entityType-${entityType.getID()}`}>
              {entityType.displayName}
            </DisplayName>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <StoredValuesList>
              {entityType.entities.map((entity, entityIndex) => (
                <StoredValuesWrapper key={entity.value}>
                  <ReferenceNameWrapper>
                    <EditEntity
                      data-test-id={`entity-name-${entity.value}`}
                      initialValue={entity.value}
                      onSubmit={async ({ value }) => {
                        entityType.entities[entityIndex].value = value;
                        document.activeElement.blur();
                        await api.patchEntityTypeById(
                          entityType.getID(),
                          entityType.getApiFormat(),
                        );
                      }}
                    />
                    <DeleteIcon
                      size="15px"
                      fill="#DA6C8C"
                      data-test-id={`entity-name-${entity.value}-delete`}
                      onClick={async e => {
                        e.stopPropagation();
                        entityType.entities.remove(entity);
                        await api.patchEntityTypeById(
                          entityType.getID(),
                          entityType.getApiFormat(),
                        );
                      }}
                    />
                  </ReferenceNameWrapper>
                  <SynonymList
                    data-test-id={`entity-synonymList-${entity.value}`}
                  >
                    {entity.synonyms.map(synonym => (
                      <SynonymListItem
                        data-test-id={`entity-synonymListItem-${synonym}`}
                        key={synonym}
                      >
                        {synonym}
                        <DeleteIcon
                          data-test-id={`stored-synonyms-delete-${synonym}`}
                          size="15px"
                          fill="#DA6C8C"
                          onClick={async e => {
                            e.stopPropagation();
                            entityType.entities[entityIndex].synonyms.remove(
                              synonym,
                            );
                            await api.patchEntityTypeById(
                              entityType.getID(),
                              entityType.getApiFormat(),
                            );
                          }}
                        />
                      </SynonymListItem>
                    ))}
                    <AddToSynonyms
                      data-test-id={`entity-newSynonym-${entity.value}`}
                      onSubmit={async (values, { resetForm }) => {
                        resetForm();
                        const newEntityType = cloneDeep(
                          entityType.getApiFormat(),
                        );
                        newEntityType.entities[entityIndex].synonyms.push(
                          values.newSynonym,
                        );
                        const updatedEntityType = await api.patchEntityTypeById(
                          entityType.getID(),
                          newEntityType,
                        );
                        entityTypesStore.entityTypes[index] = updatedEntityType;
                      }}
                    />
                  </SynonymList>
                </StoredValuesWrapper>
              ))}
            </StoredValuesList>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </>
  ));
};

export default StoredValues;
