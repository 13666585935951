import styled, { css } from 'styled-components';

export const TabList = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0em 1em;
  width: 90%;
`;

export const TabItem = styled.li`
  padding: 10px;
  font-size: 80%;
  color: ${props => (props.selected ? '#569f48' : 'inherit')};
  font-weight: ${props => (props.selected ? '600' : 'inherit')};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props =>
    props.warning &&
    css`
      color: ${props => (props.selected ? '#569f48' : '#da6c8c')};
      font-weight: 600;
    `}
`;

export const TabPanel = styled.div`
  padding: 1rem;
  width: 100%;
`;

export const TabTitle = styled.div`
  cursor: pointer;
`;

export const NoIcon = styled.div`
  min-height: 25px;
`;
