import React, { useState } from 'react';
import uuidv4 from 'uuid/v4';
import {
  EditorValuesElement,
  ButtonAlign,
  UpdateError,
  AddParameterButton,
  ParameterTypeSelect,
  ParameterFormContainer,
  RequiredContainer,
  RequiredSwitchContainer,
  RequiredOption,
  ValueSubheader,
  ParameterTitleInput,
  OpenParameterButton,
  ParameterListItem,
  DeleteWrapper,
  FallbackValueErr,
} from '../elements/EditorValues';
import ResponseBubble from '../svgs/response';
import Spanner from '../svgs/spanner';
import Delete from '../svgs/delete';
import Plus from '../svgs/plus';
import * as api from '../utils/api';
import chartStore from '../stores/chart';
import entityTypesStore from '../stores/entityTypes';
import { useObserver } from 'mobx-react';
import Switch from '@material-ui/core/Switch';
import EditorValueModal from './EditorValueModal';
import { List } from '@northcoders-dev-team/dorris-design';

function EditorValues() {
  const [errMsg, setErrMsg] = useState(null);
  const [valueTitle, setValueTitle] = useState('');
  const [valueType, setValueType] = useState('');
  const [valueRequired, setValueRequired] = useState(true);
  const [modalValueIndex, setModalValueIndex] = useState(null);

  const handleSubmit = async () => {
    if (valueTitle === '') {
      return setErrMsg('Value title cannot be empty');
    } else if (!/^[a-z0-9_-]+$/i.test(valueTitle)) {
      return setErrMsg('Value title must consist of letters,numbers,-,_');
    } else if (valueType === '') {
      return setErrMsg('Value type is required');
    } else {
      setErrMsg(null);

      const existingParameters = chartStore.getSelectedNode().intent.parameters;

      const newParameter = {
        name: uuidv4(),
        displayName: valueTitle,
        value: `$${valueTitle}`,
        entityTypeDisplayName: valueType,
        mandatory: valueRequired,
        prompts: [],
      };
      const newIntent = chartStore
        .getSelectedNode()
        .intent.getApiFormattedIntent();
      const updatedIntent = await api.patchIntentById(
        chartStore.getSelectedIntentId(),
        {
          ...newIntent,
          parameters: [newParameter, ...existingParameters],
        },
      );

      chartStore.getSelectedNode().intent.parameters = updatedIntent.parameters;

      setValueTitle('');
      setValueType('');
    }
  };

  const handleDelete = async parameter_index => {
    const intentId = chartStore.getSelectedIntentId();
    const parameters = chartStore.getSelectedNode().toJSON().intent.parameters;
    const newIntent = chartStore
      .getSelectedNode()
      .intent.getApiFormattedIntent();
    newIntent.parameters = parameters.filter(
      (parameter, index) => index !== parameter_index,
    );
    await api.patchIntentById(intentId, newIntent);
  };

  const formatValuesList = (parameter, index) => ({
    item: (
      <ParameterListItem key={parameter.name}>
        <ResponseBubble
          size="15px"
          fill={parameter.edited ? '#DA6C8C' : '#B0B9CC'}
        />
        <OpenParameterButton
          data-test-id="parameterListItem"
          onClick={() => {
            // Open modal
            setModalValueIndex(index);
          }}
        >
          {parameter.displayName}
        </OpenParameterButton>
        <Spanner size="15px" />
        <DeleteWrapper
          onClick={async () => {
            await handleDelete(index);
            parameter.removeParameter();
          }}
        >
          <Delete
            size={'15px'}
            fill={'#DA6C8C'}
            dataTestId={'valueDeleteButton'}
          />
        </DeleteWrapper>
      </ParameterListItem>
    ),
  });

  return useObserver(() => (
    <EditorValuesElement data-test-id="values">
      {chartStore.getSelectedNode().intent.isFallback ? (
        <FallbackValueErr>
          You cannot have values attached to a fallback.
        </FallbackValueErr>
      ) : (
        <>
          <ParameterTitleInput
            data-test-id="valueNameInput"
            placeholder="Choose a value name"
            onChange={e => {
              setValueTitle(e.target.value);
            }}
            value={valueTitle}
          />
          <ValueSubheader>Choose a Value Type</ValueSubheader>
          <ParameterTypeSelect
            data-test-id="valueTypeDropdown"
            onChange={e => {
              setValueType(e.target.value);
            }}
            value={valueType}
          >
            <option value={''}>Type</option>
            {entityTypesStore.entityTypes.map(entityType => {
              return (
                <option
                  key={entityType.displayName}
                  value={entityType.displayName}
                >
                  {entityType.displayName}
                </option>
              );
            })}
            {entityTypesStore.gcpEntityTypes.map(entityType => {
              return (
                <option key={entityType} value={entityType}>
                  {entityType}
                </option>
              );
            })}
          </ParameterTypeSelect>
          <ParameterFormContainer>
            <RequiredContainer>
              <ValueSubheader>Required</ValueSubheader>
              <RequiredSwitchContainer>
                <RequiredOption>No</RequiredOption>
                <Switch
                  data-test-id="valueRequiredSwitch"
                  checked={valueRequired}
                  onChange={e => {
                    setValueRequired(e.target.checked);
                  }}
                />
                <RequiredOption>Yes</RequiredOption>
              </RequiredSwitchContainer>
            </RequiredContainer>
            <AddParameterButton
              data-test-id="valueAddButton"
              onClick={e => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <ButtonAlign>
                <span>Add</span>
                <Plus fill="#ffffff" hoverFill="#ffffff" size={'15px'} />
              </ButtonAlign>
            </AddParameterButton>
          </ParameterFormContainer>

          <List
            data-test-id="valueList"
            items={chartStore
              .getSelectedNode()
              .intent.parameters.map(formatValuesList)}
          />

          <UpdateError data-test-id="updateError" errMsg={errMsg}>
            {errMsg}
          </UpdateError>
          {modalValueIndex !== null ? (
            <EditorValueModal
              modalValueIndex={modalValueIndex}
              setModalValueIndex={setModalValueIndex}
            />
          ) : null}
        </>
      )}
    </EditorValuesElement>
  ));
}

export default EditorValues;
