import React from 'react';
import { ConversationPoint } from '../elements/FlowDiagramOuter';

const FlowDiagramOuter = React.forwardRef(
  ({ node, children, ...otherProps }, ref) => {
    return (
      <ConversationPoint ref={ref} {...otherProps} bubble>
        {children}
      </ConversationPoint>
    );
  },
);
export default FlowDiagramOuter;
