import React from 'react';
import { Formik } from 'formik';
import { Form, SubmitButton, Input } from '../elements/AddToList';
import Plus from '../svgs/plus';

const AddToList = ({ onSubmit, placeholder = '', ...props }) => {
  return (
    <Formik initialValues={{ inputValue: '' }} onSubmit={onSubmit}>
      {({ values, handleChange, handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <SubmitButton type="submit">
              <Plus size={'15px'} hoverFill={'#DA6C8C'} />
            </SubmitButton>
            <Input
              {...props}
              type="text"
              name="inputValue"
              value={values.inputValue}
              onChange={handleChange}
              placeholder={placeholder || 'Enter a new Response...'}
              autoComplete="off"
            ></Input>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddToList;
