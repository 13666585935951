import styled from 'styled-components';
import DeleteIconSVG from '../svgs/delete.js';

export const Form = styled.form``;

export const AddValuesWrapper = styled.div`
  padding: 1rem 1.5rem;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const NameInput = styled.input`
  width: 75%;
  color: #7a7a7a;
  font-size: 1rem;
  margin: 0;
  border: solid 0.5px #ddd;
  padding: 0.25rem;
`;

export const Select = styled.select`
  width: 25%;
`;

export const ReferenceNameWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ReferenceNameInput = styled.input`
  color: #f15036;
  font-weight: bold;
  width: 80%;
  font-size: 1rem;
  margin: 0;
  border: solid 0.5px #ddd;
  padding: 0.25rem;
`;

export const SynonymInput = styled.input`
  color: #7a7a7a;
  width: 100%;
  font-size: 1rem;
  margin: 0;
  border: solid 0.5px #ddd;
  padding: 0.25rem;
`;

export const NewValues = styled.section`
  padding: 2rem 0;
  display: grid;
  grid-template-columns: 25% 75%;
`;

export const SynonymList = styled.ol`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const SynonymListItem = styled.li`
  display: inline-block;
  color: #da6c8c;
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  margin-right: 0.5rem;
  border: solid 0.5px #ddd;
  padding: 0.25rem 0.5rem;
`;

export const DeleteIcon = styled(DeleteIconSVG)`
  cursor: pointer;
  margin: 0 0.5rem;
`;

export const ValueLabel = styled.label``;
