import styled from 'styled-components';
import DeleteIconSVG from '../svgs/delete';

export const StoredValuesList = styled.ul`
  width: 100%;
  padding: 0;
`;

export const StoredValuesWrapper = styled.li`
  display: grid;
  grid-template-columns: 25% 75%;
  list-style-type: none;
`;

export const DisplayName = styled.div`
  width: 100%;
  font-size: 1rem;
`;

export const ReferenceNameWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ReferenceNameInput = styled.input`
  color: #f15036;
  font-weight: bold;
  width: 80%;
  font-size: 1rem;
  margin: 0;
  border: solid 0.5px #ddd;
  padding: 0.25rem;
`;

export const SynonymList = styled.ol`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
`;

export const SynonymListItem = styled.li`
  display: inline-block;
  color: #da6c8c;
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  margin-right: 0.5rem;
  border: solid 0.5px #ddd;
  padding: 0.25rem 0.5rem;
`;

export const DeleteIcon = styled(DeleteIconSVG)`
  cursor: pointer;
  margin: 0 0.5rem;
`;
