export const convertFromVwToPx = (width, winW) => {
  return width.includes('vw')
    ? Math.round(Number((width.match(/[0-9]+/g)[0] / 100) * winW))
    : Number(width.match(/[0-9]+/g)[0]);
};

export const listDisplayNames = nodes => {
  const displayNameArray = [];
  nodes.forEach(node => {
    if (node.intent) {
      displayNameArray.push(node.intent.displayName);
    }
  });

  return displayNameArray;
};

export const newCPName = nodes => {
  const displayNames = listDisplayNames(nodes);
  const newCPs = displayNames.filter(displayName =>
    displayName.includes('New Conversation Point'),
  );

  if (newCPs.length === 0) {
    return 'New Conversation Point 1';
  } else {
    const newCPIntegers = newCPs.map(cp =>
      cp === 'New Conversation Point'
        ? 1
        : cp.match(/^new conversation point (\d+)$/i)[1],
    );
    const maxInteger = Math.max(...newCPIntegers);
    const newCPname = `New Conversation Point ${maxInteger + 1}`;
    return newCPname;
  }
};
