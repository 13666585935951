import { types } from 'mobx-state-tree';

const Entity = types.model('Entity', {
  synonyms: types.array(types.string),
  value: types.string,
});

const EntityType = types
  .model('EntityType', {
    entities: types.array(Entity),
    name: types.string,
    displayName: types.string,
    kind: types.maybe(types.string),
    autoExpansionMode: types.maybe(types.string),
    enableFuzzyExtraction: types.maybe(types.boolean),
  })
  .views(self => ({
    getID() {
      const arr = self.name.split('/');
      return arr[arr.length - 1];
    },
    getApiFormat() {
      const { displayName, entities } = self.toJSON();
      return {
        displayName,
        entities,
      };
    },
  }));

export default EntityType;
