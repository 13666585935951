import React, { useState, useEffect } from 'react';
import AppElement from './elements/AppElement';
import Menu from './components/Menu';
import Values from './components/Values';
import Fallback from './components/Fallback';
import Help from './components/Help';
import { Router } from '@reach/router';
import Dialogue from './components/Dialogue';
import * as api from './utils/api';
import uuidv4 from 'uuid/v4';
import chartStore from './stores/chart';
import entityTypesStore from './stores/entityTypes';
import {
  formatIntentNodes,
  createLinks,
  addMetrics,
} from './utils/flowDiagram';

function App() {
  const [slideMenu, setSlideMenu] = useState('18vw');
  const [slideRightPanel, setSlideRightPanel] = useState('27vw');
  const [mainView, setMainView] = useState('flowDiagram');

  useEffect(() => {
    async function getInitialStores() {
      const [
        intents,
        analytics,
        gcpEntityTypes,
        customEntityTypes,
      ] = await Promise.all([
        api.getIntents(),
        api.getAnalytics(),
        api.getGCPValueTypes(),
        api.getEntityTypes(),
      ]);

      const nodes = formatIntentNodes(intents);
      const nodesWithMetrics = addMetrics(nodes, analytics);
      const links = createLinks(intents);

      chartStore.updateNodes(nodesWithMetrics);
      chartStore.links = links;
      entityTypesStore.gcpEntityTypes = gcpEntityTypes;
      entityTypesStore.entityTypes = customEntityTypes;
    }

    async function pingChatBot() {
      await api.getChatResponse('ping', uuidv4());
    }

    getInitialStores();
    pingChatBot();
  }, []);

  return (
    <AppElement slideMenu={slideMenu}>
      <Menu slideMenu={slideMenu} setSlideMenu={setSlideMenu} />
      <Router>
        <Dialogue
          slideMenu={slideMenu}
          slideRightPanel={slideRightPanel}
          setSlideRightPanel={setSlideRightPanel}
          mainView={mainView}
          setMainView={setMainView}
          path="/dialogue"
          default
        />
        <Values
          slideMenu={slideMenu}
          slideRightPanel={slideRightPanel}
          setSlideRightPanel={setSlideRightPanel}
          mainView={mainView}
          setMainView={setMainView}
          path="/values"
        />
        <Fallback
          path="/fallback"
          slideMenu={slideMenu}
          slideRightPanel={slideRightPanel}
          setSlideRightPanel={setSlideRightPanel}
          mainView={mainView}
          setMainView={setMainView}
        />
        <Help path="/help" />
      </Router>
    </AppElement>
  );
}

export default App;
