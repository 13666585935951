import styled from 'styled-components';

export const ReferenceNameInputFormWrapper = styled.div`
  width: 80%;
`;

export const ReferenceNameInput = styled.input`
  color: #f15036;
  font-weight: bold;
  width: 100%;
  font-size: 1rem;
  margin: 0;
  border: solid 0.5px #ddd;
  padding: 0.25rem;
`;
