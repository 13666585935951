import React from 'react';
import { LoadingCircle, LoadingContainer } from '../elements/TypingDots';

export default () => {
  return (
    <LoadingContainer>
      <LoadingCircle order="0.1s">●</LoadingCircle>
      <LoadingCircle order="0.3s">●</LoadingCircle>
      <LoadingCircle order="0.5s">●</LoadingCircle>
    </LoadingContainer>
  );
};
