import React, { useState, useEffect } from 'react';
import uuidv4 from 'uuid/v4';
import { Messages, MessageItem } from '@northcoders-dev-team/dorris-design';
import MessageInput from './MessageInput';
import TypingDots from './TypingDots';
import { PreviewElement, MessagesContainer } from '../elements/MessagePreview';
import DorrisAvatar from '../images/default_formal_avatar.png';
import UserAvatar from '../images/user_avatar.png';
import * as api from '../utils/api';

function MessagePreview() {
  const [typing, setTyping] = useState(false);
  const [messages, setMessages] = useState([
    {
      id: uuidv4(),
      type: 'bot',
      text: 'Use this area to test me.',
    },
  ]);
  const [sessionId] = useState(uuidv4());

  useEffect(() => {
    const getLastMessage = messages[messages.length - 1];
    const lastMessage = document.getElementById(getLastMessage.id);

    if (lastMessage) {
      lastMessage.scrollIntoView();
    }
  }, [messages]);

  const addMessage = async (text, setSubmitting) => {
    setMessages(messages => [
      ...messages,
      { id: uuidv4(), type: 'user', text },
    ]);
    setTyping(true);
    const response = await api.getChatResponse(text, sessionId);
    setMessages(messages => [
      ...messages,
      { id: uuidv4(), type: 'bot', text: response },
    ]);

    setSubmitting(false);
    setTyping(false);
  };

  return (
    <PreviewElement>
      <MessagesContainer>
        <Messages botColor="#B0B9CC" userColor="#eeeeee">
          {[
            ...messages.map(({ text, id, type }) => (
              <MessageItem
                userText="#7A7A7A"
                botText="#ffffff"
                key={id}
                messageType={type}
                avatarImagePath={type === 'bot' ? DorrisAvatar : UserAvatar}
              >
                <p key={id} data-test-id="previewMessage">
                  {text}
                </p>
                <div id={id}></div>
              </MessageItem>
            )),
            typing ? (
              <TypingDots key={'dots'} />
            ) : (
              <React.Fragment key={'no-dots'}></React.Fragment>
            ),
          ]}
        </Messages>
      </MessagesContainer>
      <MessageInput addMessage={addMessage} />
    </PreviewElement>
  );
}

export default MessagePreview;
