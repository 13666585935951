import styled from 'styled-components';
import { Button } from '@northcoders-dev-team/dorris-design';

export const EditorTrainingPhrasesElement = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  a {
    width: 100%;
  }
`;

export const ButtonAlign = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
`;

export const UpdateButton = styled(Button)`
  min-width: 180px;
  max-width: 200px;
  margin: 0 auto 0 auto;
  &:hover {
    cursor: ${props => (props.updateDisabled ? 'default' : 'pointer')};
  }
`;

export const ConversationInput = styled.input`
  padding: 0.3em;
  /* margin: 0 auto; */
  /* display: flex; */
  width: 100%;
  border: none;
  background-color: rgb(0, 0, 0, 0);
  transition: ease-in 0.3s;
  &:hover {
    transition: ease-out 0.3s;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 5px;
`;

export const DeleteWrapper = styled.div`
  display: flex;
  align-items: center;
  float: right;
`;

export const UpdateError = styled.p`
  color: #f80200;
  text-align: center;
  margin: 0;
  transition: 300ms;
  font-weight: bold;

  display: ${props => (props.errMsg ? 'inline' : 'none')};
`;
