import { types } from 'mobx-state-tree';
import { uniqBy } from 'lodash';
import ConversationNode from './ConversationNode';
import Link from './Link';

const Chart = types
  .model('Chart', {
    offset: types.model({ x: types.number, y: types.number }),
    //ConversationNode because Node is a used thing
    nodes: types.optional(types.map(ConversationNode), {}),
    links: types.optional(types.map(Link), {}),
    selected: types.model({
      type: types.maybeNull(types.string),
      id: types.maybeNull(types.string),
    }),
    hovered: types.model({
      type: types.maybeNull(types.string),
      id: types.maybeNull(types.string),
    }),
  })
  .actions(self => ({
    changeChart(newChart) {
      self = newChart;
    },
    changeOffset(newOffset) {
      self.offset = newOffset;
    },
    setInputContext(toNodeId, updatedIntent) {
      self.nodes.get(toNodeId).intent.inputContextNames =
        updatedIntent.inputContextNames;
    },
    removeNode(nodeIdDelete, deletedLinkIds) {
      for (let i = 0; i < deletedLinkIds.length; i++) {
        self.links.delete(deletedLinkIds[i]);
      }
      self.nodes.delete(nodeIdDelete);
    },
    updateNodes(newNodes, { preservePortPositions = true } = {}) {
      if (preservePortPositions) {
        for (let nodeId in newNodes) {
          const ports = newNodes[nodeId].ports;
          for (let portId in ports) {
            if (
              !ports[portId].position &&
              self.nodes.get(nodeId) &&
              self.nodes.get(nodeId).ports[portId]
            ) {
              ports[portId].position = self.nodes.get(nodeId).ports[
                portId
              ].position;
            }
          }
        }
      }
      self.nodes = newNodes;
    },
  }))
  .views(self => ({
    getSelectedNode() {
      return self.nodes.get(self.selected.id);
    },
    getFallbacks() {
      const nodes = self.toJSON().nodes;

      const filterNodes = Object.entries(nodes).filter(node => {
        return node[1].intent.isFallback === true;
      });
      return filterNodes;
    },
    getChartWithoutFallbackNodes() {
      const nodes = self.toJSON().nodes;
      const nodesWithoutFallbacks = {};
      Object.entries(nodes).forEach(([id, node]) => {
        if (node.intent.isFallback !== true) {
          nodesWithoutFallbacks[id] = node;
        }
      });

      const newChart = {
        ...self.toJSON(),
        nodes: nodesWithoutFallbacks,
      };
      return newChart;
    },
    getSelectedIntentId() {
      return self.nodes.get(self.selected.id).id.match(/\/intents\/(.*)/)[1];
    },

    isDisplayNameTaken(displayName) {
      const nodesIterator = self.nodes.values();
      let result = nodesIterator.next();
      while (!result.done) {
        if (
          result.value.intent.displayName.toLowerCase() ===
          displayName.toLowerCase()
        ) {
          return true;
        }
        result = nodesIterator.next();
      }
      return false;
    },

    getParentNodes(nodeId) {
      const parents = [];
      self.links.forEach(link => {
        if (link.to.nodeId === nodeId)
          parents.push(self.nodes.get(link.from.nodeId));
      });
      return parents;
    },
    getJointContextName(nodeId) {
      const parents = self.getParentNodes(nodeId);
      let prefix = '';
      const orderedContexts = parents
        .reduce((acc, node, i) => {
          if (i === 0) {
            prefix = /.*\/contexts\//.exec(
              node.intent.outputContexts[0].name,
            )[0];
          }
          acc.push(
            node.intent.outputContexts[0].name.match(/\/contexts\/(.+)/)[1],
          );
          return acc;
        }, [])
        .sort();
      const jointContextName = `${prefix}${orderedContexts.join('-')}`;

      return jointContextName;
    },
    getIntentsWithJointContext(toNodeId) {
      const parents = self.getParentNodes(toNodeId);
      const jointContextName = self.getJointContextName(toNodeId);
      const intentWithInputContext = {
        ...self.nodes.get(toNodeId).toJSON().intent,
        inputContextNames: jointContextName ? [jointContextName] : [],
      };
      const parentsWithJointOutput = parents.map(node => {
        const outputContexts = uniqBy(
          [
            ...node.intent.outputContexts,
            {
              name: jointContextName,
              lifespanCount: 1,
              parameters: null,
            },
          ],
          'name',
        );

        return {
          ...node.intent,
          outputContexts,
        };
      });
      const newIntents = [...parentsWithJointOutput, intentWithInputContext];
      return newIntents;
    },
  }));

export default Chart;
