import { types } from 'mobx-state-tree';

const TrainingPhrase = types.array(
  types.model('TrainingPhrase', {
    text: types.string,
    edited: types.maybeNull(types.boolean),
  }),
);

export default TrainingPhrase;
