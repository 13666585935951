import React, { useState, useEffect } from 'react';
import { TabList, TabItem, TabPanel, NoIcon, TabTitle } from '../elements/Tabs';
import ErrorIcon from '../svgs/error';
import chartStore from '../stores/chart';

export default function Tabs(props) {
  const [selected, setSelected] = useState(0);

  const handleChange = index => {
    setSelected(index);
  };

  useEffect(() => {
    setSelected(0);
  }, [chartStore.getSelectedIntentId()]);

  return (
    <>
      <TabList>
        {props.children.map((elem, index) => {
          const isSelected = index === selected ? true : false;
          return (
            <TabItem
              data-test-id={elem.props.title}
              selected={isSelected}
              key={index}
              onClick={() => handleChange(index)}
            >
              {elem.props.warning ? (
                <ErrorIcon fill="#da6c8c" size="25px"></ErrorIcon>
              ) : (
                <NoIcon />
              )}
              <TabTitle>{elem.props.title}</TabTitle>
            </TabItem>
          );
        })}
      </TabList>
      <TabPanel>{props.children[selected]}</TabPanel>
    </>
  );
}

export function Tab(props) {
  return <div>{props.children}</div>;
}
