import { rotate } from './svgs';
import chart from '../stores/chart';

export const onDragNode = ({ config, event, data, id }) => {
  let nodeChart = chart.nodes.get(id);
  const { x, y } = data;

  chart.nodes.get(id).position = {
    id: nodeChart.position.id,
    x: Math.round(x / 20) * 20,
    y: Math.round(y / 20) * 20,
  };
};

export const onDragCanvas = ({ config, event, data }) => {
  const newOffset =
    config && config.snapToGrid
      ? { x: Math.round(data.x / 20) * 20, y: Math.round(data.y / 20) * 20 }
      : data;

  chart.offset = newOffset;
};

export const onLinkStart = ({ linkId, fromNodeId, fromPortId }) => {
  chart.links.set(linkId, {
    id: linkId,
    from: {
      nodeId: fromNodeId,
      portId: fromPortId,
    },
    to: {},
  });
};

export const onLinkMove = ({ linkId, toPosition }) => {
  chart.links.get(linkId).to = {
    position: toPosition,
  };
};

export const onLinkComplete = props => {
  const {
    linkId,
    fromNodeId,
    fromPortId,
    toNodeId,
    toPortId,
    config = {},
  } = props;
  const linkAlreadyExists = [...chart.links.values()].some(
    link => link.from.nodeId === fromNodeId && link.to.nodeId === toNodeId,
  );

  if (
    !config.readonly &&
    (config.validateLink ? config.validateLink({ ...props, chart }) : true) &&
    [fromNodeId, fromPortId].join() !== [toNodeId, toPortId].join() &&
    fromPortId !== toPortId &&
    !linkAlreadyExists
  ) {
    chart.links.get(linkId).to = {
      nodeId: toNodeId,
      portId: toPortId,
    };
    if (config.onLinkAdd) config.onLinkAdd(linkId);
  } else {
    chart.links.delete(linkId);
  }
};

export const onLinkCancel = ({ linkId }) => {
  chart.links.delete(linkId);
};

export const onLinkMouseEnter = ({ linkId }) => {
  const link = chart.links.get(linkId);
  if (link.to.nodeId !== undefined && link.to.portId !== undefined) {
    if (chart.hovered.type !== 'link' || chart.hovered.id !== linkId) {
      chart.hovered = {
        type: 'link',
        id: linkId,
      };
    }
  }
};

export const onLinkMouseLeave = ({ linkId }) => {
  const link = chart.links.get(linkId);
  if (link.to.nodeId && link.to.portId) {
    chart.hovered = {};
  }
};

export const onLinkClick = ({ linkId }) => {
  if (chart.selected.id !== linkId || chart.selected.type !== 'link') {
    chart.selected = {
      type: 'link',
      id: linkId,
    };
  }
};

export const onCanvasClick = () => {
  return chart;
};

export const onDeleteKey = ({ config = {} }) => {
  const getSelectedId = chart.selected.id;
  if (chart.selected.type === 'link' && getSelectedId) {
    if (config.onLinkDelete)
      config.onLinkDelete(chart.links.get(getSelectedId));
    chart.links.delete(getSelectedId);
  }
  if (chart.selected) {
    chart.selected = {};
  }
};

export const onNodeClick = ({ nodeId }) => {
  if (chart.selected.id !== nodeId) {
    chart.selected = {
      type: 'node',
      id: nodeId,
    };
  }
};

export const onNodeSizeChange = ({ nodeId, size }) => {
  chart.nodes.get(nodeId).size = size;
};

export const onPortPositionChange = ({
  node: nodeToUpdate,
  port,
  el,
  nodesEl,
}) => {
  // return null;
  if (nodeToUpdate.size) {
    const center = {
      x: nodeToUpdate.size.width / 2,
      y: nodeToUpdate.size.height / 2,
    };
    const current = {
      x: el.offsetLeft + nodesEl.offsetLeft + el.offsetWidth / 2,
      y: el.offsetTop + nodesEl.offsetTop + el.offsetHeight / 2,
    };
    const angle = nodeToUpdate.orientation || 0;
    const position = rotate(center, current, angle);
    const node = chart.nodes.get(nodeToUpdate.id);
    node.ports[port.id].position = {
      x: position.x,
      y: position.y,
    };
  }
};

export const onCanvasDrop = ({ config, data, position }) => {
  return null;
};
