import React from 'react';
import { Formik } from 'formik';
import { Observer } from 'mobx-react';
import { useObserver } from 'mobx-react-lite';
import {
  MessageInputBox,
  Form,
  InvisibleButton,
} from '../elements/MessageInput';
import SendSVG from '../svgs/send-button';
import training from '../stores/training';

const MessageInput = ({ addMessage }) => {
  return useObserver(() => (
    <Formik
      initialValues={{ text: '' }}
      onSubmit={({ text }, { setValues, setSubmitting }) => {
        if (text) {
          addMessage(text, setSubmitting);
          setValues({ text: '' });
        } else {
          setSubmitting(false);
        }
      }}
    >
      {({ handleChange, handleSubmit, values, isSubmitting }) => (
        //  render prop must be wrapped in an <Observer> to observe changes
        <Observer>
          {() => (
            <Form onSubmit={handleSubmit}>
              <MessageInputBox
                data-test-id="messageInput"
                disabled={training.isTraining}
                placeholder={
                  training.isTraining
                    ? 'Training your bot...'
                    : 'Type a message...'
                }
                onChange={handleChange}
                type="text"
                name="text"
                id="text"
                value={values.text}
                autoComplete="off"
              ></MessageInputBox>
              <InvisibleButton type="submit" disabled={isSubmitting}>
                <SendSVG />
              </InvisibleButton>
            </Form>
          )}
        </Observer>
      )}
    </Formik>
  ));
};

export default MessageInput;
