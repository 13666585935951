import { types } from 'mobx-state-tree';

const Link = types.model('Link', {
  id: types.identifier,
  from: types.model({ nodeId: types.string, portId: types.string }),
  to: types.model({
    position: types.maybeNull(
      types.model({
        x: types.number,
        y: types.number,
      }),
    ),
    nodeId: types.maybeNull(types.string),
    portId: types.maybeNull(types.string),
  }),
});

export default Link;
