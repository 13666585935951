import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL || 'http://localhost:9090/api',
});

export const getIntents = async () => {
  const { data } = await api.get(`/intents`);
  return data.intents;
};

export const postIntents = async newIntents => {
  const { data } = await api.post(`/intents`, newIntents);
  return data.intents;
};

export const getChatResponse = async (text, sessionId) => {
  const { data } = await api.post(`/chat`, { text, sessionId });
  return data.text;
};

export const postResponses = async (newResponses, intent_id) => {
  const { data } = await api.post(
    `/intents/${intent_id}/responses`,
    newResponses,
  );
  return data.responses;
};

export const patchResponses = async (editedResponses, intent_id) => {
  const { data } = await api.patch(
    `/intents/${intent_id}/responses`,
    editedResponses,
  );
  return data.responses;
};

export const patchIntentById = async (intent_id, newIntent) => {
  const { data } = await api.patch(`/intents/${intent_id}`, newIntent);
  return data.intent;
};

export const deleteIntentById = async intent_id => {
  await api.delete(`/intents/${intent_id}`);
  return true;
};

export const patchTrainingPhrases = async (
  editedTrainingPhrases,
  intent_id,
) => {
  const { data } = await api.patch(
    `/intents/${intent_id}/training_phrases`,
    editedTrainingPhrases,
  );
  return data.intent.training_phrases;
};

export const patchPositions = async positions => {
  const { data } = await api.patch(`/positions`, positions);
  return data.positions;
};

export const deleteResponse = async (response_id, intentId) => {
  await api.delete(`/intents/${intentId}/responses/${response_id}`);
  return true;
};

export const getAnalytics = async () => {
  const { data } = await api.get(`/analytics`);
  return data.intents;
};

export const getEntityTypes = async () => {
  const { data } = await api.get(`/entityTypes`);
  return data.entityTypes;
};

export const postEntityType = async entityType => {
  const { data } = await api.post(`/entityTypes`, entityType);
  return data.entityType;
};

export const deleteEntityType = async entityTypeId => {
  await api.delete(`/entityTypes/${entityTypeId}`);
  return true;
};

export const patchEntityTypeById = async (entityTypeId, entityType) => {
  const { data } = await api.patch(`/entityTypes/${entityTypeId}`, entityType);
  return data.entityType;
};

export const resetBot = async () => {
  await api.post(`/reset`);
  return true;
};

export const getGCPValueTypes = async () => {
  const { data } = await api.get(`/values/types`);
  return data.systemEntities;
};
