import styled from 'styled-components';

export const LoadingCircle = styled.span`
  font-size: 1.2em;
  animation-delay: ${props => props.order};
  animation-duration: 0.7s;
  animation-name: slidein;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  @keyframes slidein {
    from {
      font-size: 1em;
      opacity: 1;
    }
    to {
      font-size: 0.6em;
      opacity: 0.6;
    }
  }
`;

export const LoadingContainer = styled.section`
  margin-left: 3vw;
  padding-left: 1.4em;
  @media (max-width: 500px) {
    margin-left: 7vw;
  }
  @media (max-width: 370px) {
    margin-left: 10vw;
  }
  @media (max-width: 940px) {
    margin-left: 5vw;
  }
`;
