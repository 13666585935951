import Training from '../models/Training';
import { unprotect } from 'mobx-state-tree';

const training = Training.create({
  isTraining: false,
});

unprotect(training);

export default training;
