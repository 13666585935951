import React from 'react';
import { FlowChart } from '@mrblenny/react-flow-chart';
import FlowDiagramInner from './FlowDiagramInner';
import FlowDiagramOuter from './FlowDiagramOuter';
import PortInput from './FlowDiagramPort';
import chartStore from '../stores/chart';
import trainingStore from '../stores/training';
import { FlowDiagramElement, Background } from '../elements/FlowDiagram';
import { useObserver } from 'mobx-react-lite';
import * as api from '../utils/api';

const FlowDiagram = ({ stateActions, mainView }) => {
  const updateInputContext = async linkId => {
    trainingStore.startTimeout();
    const toNodeId = chartStore.links.get(linkId).to.nodeId;
    const newIntents = chartStore.getIntentsWithJointContext(toNodeId);
    const updates = newIntents.map(intent => {
      const id = intent.name.match(/\/intents\/(.+)/)[1];
      const {
        displayName: display_name,
        trainingPhrases: training_phrases,
        inputContextNames: input_context_names,
        outputContexts: output_contexts,
        responses,
        position_id,
        x,
        y,
        ...restOfIntent
      } = intent;
      return api.patchIntentById(id, {
        ...restOfIntent,
        display_name,
        training_phrases,
        input_context_names,
        output_contexts,
      });
    });
    const updatedIntents = await Promise.all(updates);
    updatedIntents.forEach(intent => {
      chartStore.nodes[intent.name] = intent;
    });
  };

  const deleteInputContext = async link => {
    trainingStore.startTimeout();
    const toNodeId = link.to.nodeId;
    const fromNodeId = link.from.nodeId;
    const inputNode = chartStore.nodes.get(toNodeId);
    const parents = chartStore.getParentNodes(toNodeId);
    const sharedContext = chartStore.getJointContextName(toNodeId);

    chartStore.links.delete(link.id);
    parents.forEach(node => {
      // do not remove the original output context - only joint ones
      const contextToRemove = node.intent.outputContexts.find(
        (context, i) => context.name === sharedContext && i !== 0,
      );
      node.intent.outputContexts.remove(contextToRemove);
    });
    inputNode.intent.inputContextNames.remove(sharedContext);

    const newIntents = chartStore.getIntentsWithJointContext(toNodeId);
    newIntents.push(chartStore.nodes.get(fromNodeId).intent);
    const updates = newIntents.map(intent => {
      const id = intent.name.match(/\/intents\/(.+)/)[1];
      const {
        displayName: display_name,
        trainingPhrases: training_phrases,
        inputContextNames: input_context_names,
        outputContexts: output_contexts,
        responses,
        position_id,
        x,
        y,
        ...restOfIntent
      } = intent;
      return api.patchIntentById(id, {
        ...restOfIntent,
        display_name,
        training_phrases,
        input_context_names,
        output_contexts,
      });
    });
    const updatedIntents = await Promise.all(updates);
    updatedIntents.forEach(intent => {
      chartStore.nodes[intent.name] = intent;
    });
  };

  const deleteNode = async (nodeIdDelete, intentId) => {
    const deletedLinks = [];
    const deletedLinkIds = [];

    chartStore.links.forEach(link => {
      if (
        link.from.nodeId === nodeIdDelete ||
        link.to.nodeId === nodeIdDelete
      ) {
        deletedLinkIds.push(link.id);
        deletedLinks.push(deleteInputContext(link));
      }
    });

    await Promise.all(deletedLinks);
    await api.deleteIntentById(intentId);

    chartStore.removeNode(nodeIdDelete, deletedLinkIds);
  };

  return useObserver(() => (
    <FlowDiagramElement mainView={mainView}>
      <FlowChart
        chart={chartStore.getChartWithoutFallbackNodes()}
        callbacks={stateActions}
        config={{
          snapToGrid: true,
          onLinkAdd: updateInputContext,
          onLinkDelete: deleteInputContext,
          onNodeDelete: deleteNode,
        }}
        Components={{
          CanvasOuter: Background,
          Node: FlowDiagramOuter,
          NodeInner: FlowDiagramInner,
          Port: PortInput,
        }}
      />
    </FlowDiagramElement>
  ));
};

export default FlowDiagram;
