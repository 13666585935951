import styled from 'styled-components';

export const MessageInputBox = styled.input`
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 1rem;
  height: 100%;
  width: 100%;
  line-height: 1.5rem;
  color: #7a7a7a;

  border: 0;
  ::placeholder {
    color: ${props => (props.disabled ? '#da6c8c' : '#c3c6cd')};
  }
`;

export const Form = styled.form`
  border-top: 1px solid #c3c6cd;
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
`;

export const InvisibleButton = styled.button`
  border: 0;
  background: none;
`;
