import { types, getParent } from 'mobx-state-tree';

const Response = types
  .model('Response', {
    id: types.string,
    body: types.string,
    intent_id: types.maybeNull(types.string),
    persona_id: types.maybeNull(types.string),
    edited: types.maybeNull(types.boolean),
    isNew: types.maybeNull(types.boolean),
  })
  .actions(self => ({
    removeResponse() {
      getParent(self).remove(self);
    },
  }));

export default Response;
