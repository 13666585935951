import { types, getParent } from 'mobx-state-tree';

const Parameter = types
  .model('Parameter', {
    name: types.string,
    displayName: types.string,
    value: types.string,
    entityTypeDisplayName: types.string,
    mandatory: types.boolean,
    prompts: types.array(types.string),
  })
  .actions(self => ({
    removeParameter() {
      getParent(self).remove(self);
    },
  }));

export default Parameter;
