import styled from 'styled-components';

export const MenuUserInfoElement = styled.div`
  padding: 1em;
  display: flex;
  justify-content: space-around;
  width: 80%;
`;

export const UserImage = styled.img`
  height: 50px;
  border-radius: 50px;
  box-shadow: 0px 2px 5px rgba(198, 198, 198, 0.25);
`;

export const UserName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
  width: 100%;
  font-family: 'Muli';
  font-weight: Bold;
  font-size: 0.8em;
  color: #c3c6cd;
`;

export const LogoutButton = styled.button`
  .arrowOut,
  .door {
    fill: #b0b9cc;
    cursor: pointer;
    transition: ease-out 0.1s;
  }

  background-color: none;
  border: none;
  cursor: pointer;
  transition: ease-out 0.1s;

  &:hover {
    .arrowOut,
    .door {
      transition: ease-in 0.05s;
      fill: gray;
    }
    transition: ease-in 0.05s;
  }
`;
