import EntityTypes from '../models/EntityTypes';
import { unprotect } from 'mobx-state-tree';

// populated from api
const entityTypes = EntityTypes.create({
  entityTypes: [],
});

unprotect(entityTypes);

export default entityTypes;
