import React from 'react';

export default props => {
  let { size = '25px', fill = '#B0B9CC' } = props;
  return (
    <svg viewBox="0 0 28 28" height={size} width={size}>
      <g id="x">
        <polygon
          id="tabImageFallback"
          fill={fill}
          points="28,22.398 19.594,14 28,5.602 22.398,0 14,8.402 5.598,0 0,5.602 8.398,14 0,22.398 
				5.598,28 14,19.598 22.398,28"
        />
      </g>
    </svg>
  );
};
