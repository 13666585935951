import React from 'react';

export default ({ fill = '#C3C6CD', size = '25px' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 535.5 535.5">
      <g>
        <g id="send">
          <polygon
            fill={fill}
            points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75 		"
          />
        </g>
      </g>
    </svg>
  );
};
